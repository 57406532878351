import Axios from "axios";

const job = () => {


    const getJob = async (VeranstaltungsID) => {
        const response = await Axios.get(`/api/Jobs/${VeranstaltungsID}`)
        return response.data
    }

    const getAllJobs = async () => {
        const response = await Axios.get('/api/Jobs')
        return response.data
    }

    const getAllopenJobs = async () => {
        const response = await Axios.get('/api/Jobs/openJobs/')
        return response.data
    }


    const createJob = async (Startzeit, VeranstaltungsID, Beschreibung, Endzeit, Stundenlohn, Zuschlag, Pausenzeit, StatusID) => {
        const response = await Axios.post('/api/Jobs/add', {
            Startzeit,
            VeranstaltungsID,
            Beschreibung,
            Endzeit,
            Stundenlohn,
            Zuschlag,
            Pausenzeit,
            StatusID
        })
        return response
    }


    const updateJob = async (idJobs, Startzeit, Beschreibung, Endzeit, Stundenlohn, Zuschlag, Pausenzeit, StatusID) => {
        await Axios.put(`/api/Jobs/${idJobs}`, {
            Startzeit,
            Beschreibung,
            Endzeit,
            Stundenlohn,
            Zuschlag,
            Pausenzeit,
            StatusID
        })

    }

    const updateStatus = async (idJobs, StatusID) => {
        await Axios.put("/api/Jobs/changeStatus", {
            idJobs,
            StatusID
        })
    }

    const deleteJob = async (idJobs) => {
        await Axios.delete(`/api/Jobs/${idJobs}`)

    }


    return {getJob, getAllJobs, getAllopenJobs,updateStatus,createJob,updateJob,deleteJob}


}
export default job