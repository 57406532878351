import React,{useState} from "react";
import {Button, Grid, TextField} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import mitarbeiter from "../Models/mitarbeiter";
import useSession from "../ContextProvider/Session";
import {Redirect} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


function Home(props){
    const [Email,setEmail]=useState("")
    const {user,getUser} =useSession()
    const [Passwort,setPasswort] = useState("")
    const [loginstatus,setLoginStatus] = useState("")
    const {getLogin} = mitarbeiter()
    const referer = props.location.state?.referer || '/calender'

    const classes = useStyles();

    const onLogin = async () =>{
        const response = await getLogin(Email,Passwort)
        if(response.data == null)
        {
            setLoginStatus("Email oder Passwort ist Falsch")
            alert("Email oder Passwort ist Falsch")
        }else{
            console.log(response)
            getUser()

        }

    }

    const onKeyUp = event => {
        if (event.key === 'Enter')
            onLogin()
    }


    if (user)
    {
        return <Redirect to={referer}/>
    }
    return(
        <Grid>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper} >
                    <img alt="Logo"  width="187" height="39" src="https://cdn.discordapp.com/attachments/533392699269578763/776788792504877056/NK-Software-Logo_fur_Web.png" />
                    <Typography component="h2" variant="h5">
                        Log In
                    </Typography>
                    <form className={classes.form}  noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Adresse"
                            name="Email"
                            autoComplete="Email"
                            value={Email}
                            onChange={(event) =>setEmail(event.target.value)}
                            autoFocus
                            onKeyUp={onKeyUp}

                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="Passwort"
                            label="Passwort"
                            type="password"
                            value={Passwort}
                            onChange={event => setPasswort(event.target.value)}
                            id="password"
                            autoComplete="current-password"
                            onKeyUp={onKeyUp}
                        />

                        <Button variant={"outlined"} className={classes.submit} onClick={onLogin} >Einloggen</Button>
                    </form>
                </div>

            </Container>
            <h1 align={'center'} style={{color:'#ff0000'}}>{loginstatus}</h1>
        </Grid>
    );
}
export default Home;