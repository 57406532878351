
import Axios from "axios";

const jobMitarbeiter = () => {


    const getJobEmployees = async (Jobs_ID) => {
        const response = await Axios.get(`/api/JobsEmployees/${Jobs_ID}`
        )
        return response.data

    }
    const getMyJobs = async (Mitarbeiter_ID) =>{

        const response = await Axios.get(`/api/JobsEmployees/MyJobs/${Mitarbeiter_ID}`)

        return response.data

    }


    const getMyOldJobs = async (Mitarbeiter_ID) =>{

        const response = await Axios.get(`/api/JobsEmployees/MyOldJobs/${Mitarbeiter_ID}`)

        return response.data

    }



    const getMitarbeiterJobCount = async (Mitarbeiter_ID) => {

        const response = await Axios.get(`/api/JobsEmployees/count//${Mitarbeiter_ID}`)
        return response.data

    }


    const createJobEmployee = async (Jobs_ID, Mitarbeiter_ID) => {
        const response = await Axios.post('/api/JobsEmployees/add', {
            Jobs_ID,
            Mitarbeiter_ID
        })
        return response
    }

    const getJobperMonth = async (Mitarbeiter_ID,untereGrenze,obereGrenze) =>{

        const response  = await Axios.post(`/api/JobsEmployees/JobsimMonat/${Mitarbeiter_ID}`,{
            untereGrenze,
            obereGrenze
        })
        return response.data
    }

    const updateJobEmployee = async (Jobs_ID,Mitarbeiter_ID) =>{

        const response = await Axios.put(`/api/JobsEmployees/${Jobs_ID}`,{
            Jobs_ID,
            Mitarbeiter_ID
        })
        return response.data
    }

    const deleteJObEmployee = async (Jobs_ID)=>{

        await Axios.delete(`/api/JobsEmployees/${Jobs_ID}`)

    }

    return {getJobEmployees,getMyJobs,getMyOldJobs,getMitarbeiterJobCount,createJobEmployee,getJobperMonth,updateJobEmployee,deleteJObEmployee}

}
export default jobMitarbeiter