import React, {createContext, useContext, useState} from 'react'
import axios from 'axios'


const SessionContext = createContext()

export const SessionProvider = ({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [user, setUser] = useState(null)

    return <SessionContext.Provider
        value={{isLoggedIn, setIsLoggedIn, user, setUser}}>{children}</SessionContext.Provider>
}

export default function useSession() {
    const {isLoggedIn, setIsLoggedIn, user, setUser} = useContext(SessionContext)

    function getUser() {
        const token = document.cookie.split('token=')[1]

        if (token) {
            try {
                setUser(JSON.parse(atob(token.split('.')[1])));


            } catch (error) {
                // ignore
            }
        }
    }

    const login = async (Email, Passwort) => {
        const data = {Email, Passwort}
        try {
            const response = await axios.post('/api/Employees/login', data)
            setUser(response.data)
            setIsLoggedIn(true)
            return true
        } catch (e) {
            return false
        }
    }

    const logout = async () => {
        try {
            await axios.get('/api/Employees/logout')
            setUser(null)
            setIsLoggedIn(false)
        } catch (e) {
            console.log(e)
        }
    }


    return {
        isLoggedIn,
        user,
        setUser,
        login,
        logout,
        getUser,

    }
}