import React, {useState, useEffect} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {lighten, makeStyles, withStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import {Hidden, InputBase, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import mitarbeiter from "../Models/mitarbeiter";
import mitarbeiterQualifikationen from "../Models/mitarbeiterQualifikationen";
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Axios from "axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import useSession from "../ContextProvider/Session";

const useStyle = makeStyles((theme) => ({

    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        width: '100%',
    },
    divider: {
        display: "inline-block",
        width: "80%",
        backgroundColor: '#0e4194',

    },
    row: {
        backgroundColor: '#0e4194'
    },
    Toolbarroot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    DialogTitlehintergrunde: {
        backgroundColor: '#0e4194'
    },
    DialogTitlefarbe: {
        color: '#FFFFFF'
    }

}))
const BootstrapButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#a80b2a',
        borderColor: '#a80b2a',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },

    },
})(Button);
const NkblauButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#0e4194',
        borderColor: '#0e4194',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
        },

    },
})(Button);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Nachname',
        numeric: false,
        disablePadding: true,
        label: 'Nachname',
    },
    {
        id: 'Vorname',
        numeric: false, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Vorname',
    },
    {
        id: 'Email',
        numeric: false, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Email',
    },
    {
        id: 'Rolle',
        numeric: false, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Rolle',
    },
    {
        id: 'Aktionen',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Aktionen',
    },
];


function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow style={{backgroundColor: '#0e4194'}}>
                <TableCell align={headCells[0].numeric ? 'right' : 'left'}
                           padding={headCells[0].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[0].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[0].id}
                        direction={orderBy === headCells[0].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[0].id)}
                    >
                        <Typography variant={'h6'}>{headCells[0].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <TableCell align={headCells[1].numeric ? 'right' : 'left'}
                           padding={headCells[1].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[1].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[1].id}
                        direction={orderBy === headCells[1].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[1].id)}
                    >
                        <Typography variant={'h6'}>{headCells[1].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <Hidden xsDown>
                    <TableCell align={headCells[2].numeric ? 'right' : 'left'}
                               padding={headCells[2].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[2].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[2].id}
                            direction={orderBy === headCells[2].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[2].id)}
                        >
                            <Typography variant={'h6'}>{headCells[2].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align={headCells[3].numeric ? 'right' : 'left'}
                               padding={headCells[3].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[3].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[3].id}
                            direction={orderBy === headCells[3].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[3].id)}
                        >
                            <Typography variant={'h6'}>{headCells[3].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                </Hidden>
                <TableCell align={headCells[4].numeric ? 'right' : 'left'}
                           padding={headCells[4].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[4].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[4].id}
                        direction={orderBy === headCells[4].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[4].id)}
                    >
                        <Typography variant={'h6'}>{headCells[4].label}</Typography>
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {

    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function Mitarbeitergruppen() {
    const classes = useStyle();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Nachname');
    const [openCreate, setOpenCreate] = useState(false)
    const [currentMitarbeiter, setCurrentMitarbeiter] = useState(null)
    const [Vorname, setVorname] = useState(null)
    const [Nachname, setNachname] = useState(null)
    const [Adresse, setAdresse] = useState(null)
    const [Geschlecht, setGeschlecht] = useState(null)
    const [Wohnort, setWohnort] = useState(null)
    const [Rolle, setRolle] = useState(3)
    const [Handynummer, setHandynummer] = useState(null)
    const [FestnetzNummer, setFestnetzNummer] = useState(null)
    const {
        getMitarbeiter,
        createMitarbeiter,
        updateMitarbeiterwithPassword,
        updateMitarbeiterwithoutPassword,
        deleteMitarbeiter,
        suchMitarbeiter
    } = mitarbeiter()
    const [suche, setSuche] = useState(null)
    const [Email, setEmail] = useState(null)
    const [Passwort, setPasswort] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [liste, setListe] = useState([])
    const [qualifications, setQualifications] = useState([])
    const [checked, setChecked] = useState([])
    const [openAddQualifikationen, setopenAddQualifikationen] = useState(false)
    const {user} = useSession()
    const {
        getMitarbeiterQualification,
        createMitarbeiterQualification,
        deleteMitarbeiterQualification
    } = mitarbeiterQualifikationen()

    useEffect(() => {
        const fetchdata = async () => {
            getMitarbeiter().then(li => {
                setListe(li)
            })
        }
        if (suche == null || typeof suche == "undefined" || suche.length === 0)
            fetchdata()
    }, [liste])

    useEffect(() => {

        if (suche != null && typeof suche != "undefined") {
            suchMitarbeiter(suche).then(response => {
                setListe(response)
            })
        }

    }, [suche])

    useEffect(() => {
        if (currentMitarbeiter != null) {
            getMitarbeiterQualification(currentMitarbeiter.idMitarbeiter).then(li => {
                setChecked(li.reduce((acc, cur) => [...acc, cur.QualifikationenID], []))

            })
        }

    }, [currentMitarbeiter])

    const fetchQualis = async () => {
        const response = await Axios.get('/api/Qualificationen')
        setQualifications(response.data)

    }

    function openCreateDialog() {
        //Function to open CreateDialog
        setOpenCreate(true)
    }

    function closeCreateDialog() {
        //Function to close CreateDialog
        setOpenCreate(false)
        setNachname(null)
        setVorname(null)
        setAdresse(null)
        setEmail(null)
        setPasswort(null)
        setGeschlecht(null)
        setRolle(3)
        setWohnort(null)
        setFestnetzNummer(null)
        setHandynummer(null)

    }

    function openEditDialog(s) {
        //Function to open EditDialog
        setCurrentMitarbeiter(s)
        setNachname(s.Nachname)
        setVorname(s.Vorname)
        setAdresse(s.Adresse)
        setEmail(s.Email)
        setGeschlecht(s.Geschlecht)
        setRolle(s.Rolle)
        setWohnort(s.Wohnort)
        setHandynummer(s.Handynummer)
        setFestnetzNummer(s.FestnetzNummer)
        setOpenEdit(true)

    }

    function closeEditDialog() {
        //Function to close EditDialog
        setOpenEdit(false)
        setNachname(null)
        setVorname(null)
        setAdresse(null)
        setWohnort(null)
        setEmail(null)
        setPasswort(null)
        setGeschlecht(null)
        setFestnetzNummer(null)
        setHandynummer(null)

    }

    function openDeleteDialog(s) {
        setCurrentMitarbeiter(s)
        setOpenDelete(true)
    }

    function closeDeleteDialog() {
        setOpenDelete(false)
    }

    function openAddQualificationDialog(index) {
        setCurrentMitarbeiter(index)
        setopenAddQualifikationen(true)
    }

    function closeAddQualificationDialog() {
        setCurrentMitarbeiter(null)
        setChecked([])
        setopenAddQualifikationen(false)
    }

    function RolleKonvertieren(row) {

        if (row === 1) {
            return "Admin"
        }
        if (row === 2) {
            return "Personalverwaltung"
        }
        if (row === 3) {
            return "Mitarbeiter*innen"
        }
    }


    const addMItarbeiterQualification = async () => {
        await deleteMitarbeiterQualification(currentMitarbeiter.idMitarbeiter)
        await createMitarbeiterQualification(currentMitarbeiter.idMitarbeiter, checked)
        closeAddQualificationDialog()
    }

    const addMitarbeiter = async () => {

        if (Vorname == null || Vorname === "" || Vorname.startsWith(" ") || Nachname == null || Nachname === "" || Nachname.startsWith(" ") ||
            Geschlecht == null || Email == null || Email === "" || Email.startsWith(" ") ||
            Passwort == null || Passwort === "" || Passwort.startsWith(" ")) {
            alert("Eins der Pflichtfelder ist nicht ausgefüllt oder beginnt mit einer Leertaste")
        } else {
            const neuerMitarbeiter = await createMitarbeiter(Vorname, Nachname, Adresse, Geschlecht, Wohnort, Email, Passwort, Rolle, FestnetzNummer, Handynummer)
            setListe([...liste, neuerMitarbeiter])
            closeCreateDialog()
        }


    }
    const onUpdateMitarbeiter = async () => {

        if (Vorname == null || Vorname === "" || Vorname.startsWith(" ") || Nachname == null || Nachname === "" || Nachname.startsWith(" ") ||
            Geschlecht == null || Email == null || Email === "" || Email.startsWith(" ")) {
            alert("Eins der Pflichtfelder ist nicht ausgefüllt oder beginnt mit einer Leertaste")
        } else {
            if (Passwort != null) {
                await updateMitarbeiterwithPassword(currentMitarbeiter.idMitarbeiter, Vorname, Nachname, Adresse, Geschlecht, Wohnort, Email, Passwort, Rolle, FestnetzNummer, Handynummer)
            } else {
                await updateMitarbeiterwithoutPassword(currentMitarbeiter.idMitarbeiter, Vorname, Nachname, Adresse, Geschlecht, Wohnort, Email, Rolle, FestnetzNummer, Handynummer)
            }
            setListe(alterMitarbeiter => alterMitarbeiter.map(mitarbeiter => {
                if (mitarbeiter.idMitarbeiter === currentMitarbeiter.idMitarbeiter) {
                    return {
                        idMitarbeiter: mitarbeiter.idMitarbeiter,
                        Vorname: Vorname,
                        Nachname: Nachname,
                        Adresse: Adresse,
                        Geschlecht: Geschlecht,
                        Wohnort: Wohnort,
                        Email: Email,
                        Passwort: mitarbeiter.Passwort,
                        Rolle: Rolle,
                        FestnetzNummer: FestnetzNummer,
                        Handynummer: Handynummer
                    }
                } else {
                    return mitarbeiter
                }
            }))
            closeEditDialog()
        }

    }
    const onDeleteMitarbeiter = async () => {
        await deleteMitarbeiter(currentMitarbeiter.idMitarbeiter)
        setListe(oldMitarbeiter => oldMitarbeiter.filter(mitarbeiter =>
            currentMitarbeiter.idMitarbeiter !== mitarbeiter._id))
        setOpenDelete(false)
    }


    const handleCheckboxToggle = (value) => () => {

        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)

        } else {
            newChecked.splice(currentIndex, 1)
        }
        setChecked(newChecked)

    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (
        <div className={classes.root}>
            <Toolbar className={classes.Toolbarroot}>
                <BootstrapButton className={classes.test} variant={'outlined'}
                                 onClick={() => openCreateDialog()}> Mitarbeiter
                    Erstellen</BootstrapButton>
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    align={"center"}
                >
                    Mitarbeiter Verwaltung
                </Typography>
                <InputBase
                    placeholder="Suchen(Nachname)"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    value={suche}
                    onChange={e => setSuche(e.target.value)}
                    inputProps={{'aria-label': 'search'}}
                />
            </Toolbar>
            <Paper className={classes.paper}>
                <TableContainer>

                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={liste.length}
                            />
                            <TableBody>
                                {stableSort(liste, getComparator(order, orderBy))
                                    .map((row) => {
                                        return (
                                            <TableRow key={row.idMitarbeiter}
                                                      hover>
                                                <TableCell align="left">{row.Nachname}</TableCell>
                                                <TableCell align="left">{row.Vorname}</TableCell>
                                                <Hidden xsDown>
                                                    <TableCell align="left">{row.Email}</TableCell>
                                                    <TableCell
                                                        align="left">{RolleKonvertieren(row.Rolle)}</TableCell>
                                                </Hidden>
                                                <TableCell align="right">
                                                    <Tooltip title={"Qualifikationen hinzufügen"}>
                                                        <IconButton onClick={() => {
                                                            if (user.Rolle !== 1 && row.Rolle === 1) {
                                                                console.log("Nicht die nötigen rechte")
                                                            } else {
                                                                fetchQualis()
                                                                openAddQualificationDialog(row)
                                                            }

                                                        }}>
                                                            <AddCircleOutlineIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Mitarbeiter bearbeiten"}>
                                                        <IconButton onClick={() => {
                                                            if (user.Rolle !== 1 && row.Rolle === 1) {
                                                                console.log("Nicht die nötigen rechte")
                                                            } else {
                                                                openEditDialog(row)
                                                            }
                                                        }
                                                        }>
                                                            <EditIcon style={{color: '#0e4194'}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Mitarbeiter löschen"}>
                                                        <IconButton onClick={() => {
                                                            if (user.Rolle !== 1 && row.Rolle === 1) {
                                                                console.log("Nicht die nötigen rechte")
                                                            } else {
                                                                openDeleteDialog(row)
                                                            }
                                                        }
                                                        }>
                                                            <DeleteIcon style={{color: '#a80b2a'}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </TableContainer>
            </Paper>
            <Dialog open={openCreate} aria-labelledby="form-dialog-title" fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Mitarbeiter erstellen</DialogTitle>
                </div>
                <DialogContentText align={'center'}>
                    Hier können Sie einen Mitarbeiter hinzufügen.
                </DialogContentText>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="vorname"
                        label="Vorname *"
                        value={Vorname}
                        onChange={event => setVorname(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="nachname"
                        label="Nachname *"
                        value={Nachname}
                        onChange={event => setNachname(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="adresse"
                        label="Adresse *"
                        value={Adresse}
                        onChange={event => setAdresse(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="wohnort"
                        label="Wohnort *"
                        value={Wohnort}
                        onChange={event => setWohnort(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Handynummer"
                        label="Handynummer"
                        value={Handynummer}
                        onChange={event => setHandynummer(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Festnetznummer"
                        label="Festnetznummer"
                        value={FestnetzNummer}
                        onChange={event => setFestnetzNummer(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">Geschlecht</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={event => setGeschlecht(event.target.value)
                            }
                            label="Geschlecht *"
                            value={Geschlecht}
                        >
                            <MenuItem value={"Männlich"}>Männlich</MenuItem>
                            <MenuItem value={"Weiblich"}>Weiblich</MenuItem>
                            <MenuItem value={"Divers"}>Divers</MenuItem>
                        </Select>
                    </FormControl>
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email *"
                        value={Email}
                        onChange={event => setEmail(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="passwort"
                        label="Passwort *"
                        value={Passwort}
                        onChange={event => setPasswort(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">Rolle</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={event => setRolle(event.target.value)
                            }
                            label="Rolle *"
                            value={Rolle}
                        >
                            <MenuItem value={1}>Admin</MenuItem>
                            <MenuItem value={2}>Personalverwaltung</MenuItem>
                            <MenuItem value={3}>Mitarbeiter*innen</MenuItem>
                        </Select>
                    </FormControl>
                    <p/>
                </DialogContent>
                <DialogActions>
                    <NkblauButton onClick={addMitarbeiter}>Erstellen</NkblauButton>
                    <BootstrapButton onClick={closeCreateDialog}>Abbrechen</BootstrapButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} aria-labelledby="form-dialog-title" fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Mitarbeiter bearbeiten</DialogTitle>
                </div>
                <DialogContentText align={'center'}>
                    Hier können Sie einen Mitarbeiter bearbeiten.
                </DialogContentText>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Editvorname"
                        label="Vorname*"
                        value={Vorname}
                        onChange={event => setVorname(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Editnachname"
                        label="Nachname*"
                        value={Nachname}
                        onChange={event => setNachname(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Editadresse"
                        label="Adresse*"
                        value={Adresse}
                        onChange={event => setAdresse(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Editadresse"
                        label="Wohnort"
                        value={Wohnort}
                        onChange={event => setWohnort(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="adresse"
                        label="Handynummer"
                        value={Handynummer}
                        onChange={event => setHandynummer(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="adresse"
                        label="Festnetznummer"
                        value={FestnetzNummer}
                        onChange={event => setFestnetzNummer(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">Geschlecht</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={event => setGeschlecht(event.target.value)
                            }
                            label="Geschlecht*"
                            value={Geschlecht}
                        >
                            <MenuItem value={"Männlich"}>Männlich</MenuItem>
                            <MenuItem value={"Weiblich"}>Weiblich</MenuItem>
                            <MenuItem value={"Divers"}>Divers</MenuItem>
                        </Select>
                    </FormControl>
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email*"
                        value={Email}
                        onChange={event => setEmail(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Editpasswort"
                        label="Passwort"
                        value={Passwort}
                        onChange={event => setPasswort(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">Rolle</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={event => setRolle(event.target.value)
                            }
                            label="Rolle*"
                            value={Rolle}
                            fullWidth={true}
                        >
                            <MenuItem value={1}>Admin</MenuItem>
                            <MenuItem value={2}>Personalverwaltung</MenuItem>
                            <MenuItem value={3}>Mitarbeiter*innen</MenuItem>
                        </Select>
                    </FormControl>
                    <p/>
                </DialogContent>
                <DialogActions>
                    <NkblauButton onClick={onUpdateMitarbeiter} color="primary">Speichern</NkblauButton>
                    <BootstrapButton onClick={closeEditDialog} color="primary">Abbrechen</BootstrapButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} aria-labelledby="form-dialog-title" fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Mitarbeiter löschen</DialogTitle>
                </div>
                <DialogContentText align={'center'}>
                    Möchten Sie den Mitarbeiter wirklich löschen?
                </DialogContentText>
                <DialogActions>
                    <NkblauButton onClick={onDeleteMitarbeiter} color="primary">Ja</NkblauButton>
                    <BootstrapButton onClick={closeDeleteDialog} color="primary">Nein</BootstrapButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openAddQualifikationen} onClose={() => {
                setopenAddQualifikationen(false)
            }} aria-labelledby="form-dialog-title" fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Qulifikationen hinzufügen</DialogTitle>
                </div>
                <DialogContent>
                    <FormGroup>
                        Ausgewählte Qualifikationen
                        <List dense className={classes.userList}>
                            {qualifications?.filter(Quali => checked.indexOf(Quali.idQualifikationen) >= 0).map((quali, index) => (
                                    <ListItem key={index} button>
                                        <ListItemText id={index} primary={quali.Typ}/>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={handleCheckboxToggle(quali.idQualifikationen)}
                                                checked={checked.indexOf(quali.idQualifikationen) !== -1}
                                                inputProps={{'aria-labelledby': index}}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ) || <Button/>
                            )}
                        </List>
                        <p/>
                        Restliche Qualifikatioen
                        <List dense className={classes.userList}>

                            {qualifications?.filter(Quali => checked.indexOf(Quali.idQualifikationen) === -1).map((quali, index) => (
                                    <ListItem key={index} button>

                                        <ListItemText id={index} primary={quali.Typ}/>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={handleCheckboxToggle(quali.idQualifikationen)}
                                                checked={checked.indexOf(quali.idQualifikationen) !== -1}
                                                inputProps={{'aria-labelledby': index}}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ) || <Button/>
                            )}
                        </List>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <NkblauButton onClick={addMItarbeiterQualification} color="primary">Speichern</NkblauButton>
                    <BootstrapButton onClick={() => {
                        closeAddQualificationDialog()
                    }} color="primary">Abbrechen</BootstrapButton>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default Mitarbeitergruppen;