import React, {useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {AppBar, Toolbar, Button, Menu, ListItemText} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import useSession from "../ContextProvider/Session";
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from "@material-ui/core/ListItem";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
    },
    menuButton: {
        marginRight: theme.spacing(50),
    },
    title: {
        flexGrow: 2,
    },
    margin: {
        margin: theme.spacing(1),
    },

    menue: {
        display: 'flex',
    }

}));


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        color: '#0e4194',
        '&:hover': {
            backgroundColor: '#0e4194',
            color: '#FFFFFF',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {},
        },
    },
}))(MenuItem);


const NavButton = withStyles({

    root: {
        color: '#0e4194',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: 'primary',
        borderColor: 'primary',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
            boxShadow: 'none',
            color: '#FFFFFF',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            backgroundColor: '#0e4194',
            color: '#FFFFFF',
        },

    },
})(Button);

const LogoutButton = withStyles({

    root: {
        color: '#a80b2a',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: 'primary',
        borderColor: 'primary',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
            color: '#FFFFFF',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            backgroundColor: '#a80b2a',
            color: '#FFFFFF',
        },

    },
})(Button);


function Nav({children}) {
    const {user, logout} = useSession()
    const classes = useStyles();
    let history = useHistory();
    let loggedIn = false
    if (user) {
        loggedIn = true
    }
    const [anchorEl, setAnchorEl] = useState(null);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div className={classes.root}>

            <AppBar position="static" color={'transparent'}>
                <Toolbar>
                    <Grid container>

                        <img alt="Logo" width="187" height="39"
                             src="https://cdn.discordapp.com/attachments/533392699269578763/776788792504877056/NK-Software-Logo_fur_Web.png"/>
                        <Hidden smDown>
                            {loggedIn && (<>
                                {user?.Rolle <= 2 && <NavButton className={classes.margin}
                                                                onClick={() => history.push('/Buchhaltung')}>Buchhaltung</NavButton>}
                                {user?.Rolle <= 2 && <NavButton className={classes.margin}
                                                                onClick={() => history.push('/arbeitsanfragen')}>Arbeitsanfragen</NavButton>}
                                <NavButton className={classes.margin}
                                           onClick={() => history.push('/calender')}>Kalender</NavButton>
                                {user?.Rolle === 1 && <NavButton className={classes.margin}
                                                                 onClick={() => history.push('/QualifikationenErstellen')}>Qualifikationen
                                </NavButton>}
                                {user?.Rolle <= 2 && <NavButton className={classes.margin}
                                                                onClick={() => history.push('/mitarbeitergruppen')}>Personal</NavButton>}
                                {user?.Rolle <= 2 && <NavButton className={classes.margin}
                                                                onClick={() => history.push('/veranstaltungenbearbeiten')}>Events</NavButton>}
                                <NavButton className={classes.margin} onClick={() => history.push("Stellenangebote")}>Offene
                                    Jobs</NavButton>
                                <NavButton className={classes.margin} aria-controls="simple-menu" aria-haspopup="true"
                                           onClick={handleClick}>Mein Bereich</NavButton>
                                <StyledMenu
                                    id="customized-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <StyledMenuItem onClick={handleClose}>
                                        <ListItem onClick={() => history.push('/MeineAnfragen')}>
                                            Meine Anfragen
                                        </ListItem>
                                    </StyledMenuItem>
                                    <StyledMenuItem onClick={handleClose}>
                                        <ListItem onClick={() => history.push('/MeineJobs')}>
                                            Meine Jobs
                                        </ListItem>
                                    </StyledMenuItem>
                                    <StyledMenuItem onClick={handleClose}>
                                        <ListItem onClick={() => history.push('/VergangendeJobs')}>
                                            Vergangene Jobs
                                        </ListItem>
                                    </StyledMenuItem>
                                    <StyledMenuItem onClick={handleClose}>
                                        <ListItem onClick={() => history.push('/MeineBuchhaltung')}>
                                            Meine Buchaltung
                                        </ListItem>
                                    </StyledMenuItem>
                                    <StyledMenuItem onClick={handleClose}>
                                        <ListItem onClick={() => history.push('/Meinprofil')}>
                                            Mein Profil
                                        </ListItem>
                                    </StyledMenuItem>
                                </StyledMenu>
                                <LogoutButton className={classes.margin} onClick={() => logout()}>Logout</LogoutButton>
                            </>)}
                        </Hidden>
                        <Hidden mdUp>
                            {loggedIn && (<>
                                <div>
                                    <Grid item spacing={5}>
                                        <NavButton
                                            aria-controls="customized-menu"
                                            aria-haspopup="true"

                                            onClick={handleClick}
                                        >
                                            Menü
                                        </NavButton>
                                    </Grid>
                                    <StyledMenu
                                        id="customized-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        hover
                                    >
                                        <StyledMenuItem>
                                            {user?.Rolle <= 2 && <ListItem className={classes.margin}
                                                                           onClick={() => history.push('/Buchhaltung')}>Buchhaltung</ListItem>}
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            {user?.Rolle <= 2 && <ListItem className={classes.margin}
                                                                           onClick={() => history.push('/arbeitsanfragen')}>Arbeitsanfragen</ListItem>}
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItem color={'#FFFFFF'} className={classes.margin}
                                                      onClick={() => history.push('/calender')}>Kalender</ListItem>
                                        </StyledMenuItem>
                                        {user?.Rolle === 1 && <StyledMenuItem>
                                            <ListItem className={classes.margin}
                                                      onClick={() => history.push('/QualifikationenErstellen')}>Qualifikationen
                                                </ListItem>
                                        </StyledMenuItem>}
                                        {user?.Rolle <= 2 && <StyledMenuItem>
                                            <ListItem className={classes.margin}
                                                      onClick={() => history.push('/mitarbeitergruppen')}>Personal</ListItem>
                                        </StyledMenuItem>}
                                        {user?.Rolle <= 2 && <StyledMenuItem>
                                            <ListItem className={classes.margin}
                                                      onClick={() => history.push('/veranstaltungenbearbeiten')}>Events
                                                verwaltung</ListItem>
                                        </StyledMenuItem>}
                                        <StyledMenuItem>
                                            <ListItem className={classes.margin}
                                                      onClick={() => history.push('/Stellenangebote')}>Offene
                                                Jobs</ListItem>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItem className={classes.margin}
                                                      onClick={() => history.push('/VergangendeJobs')}>Vergangene
                                                Jobs</ListItem>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItem className={classes.margin}
                                                      onClick={() => history.push('/Meinprofil')}>Mein
                                                Profil </ListItem>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItem className={classes.margin}
                                                      onClick={() => history.push('/MeineAnfragen')}>Meine
                                                Anfragen </ListItem>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItem className={classes.margin}
                                                      onClick={() => history.push('/MeineJobs')}>Meine Jobs </ListItem>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItem className={classes.margin}
                                                      onClick={() => history.push('/MeineBuchhaltung')}>Meine
                                                Buchaltung </ListItem>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <ListItem className={classes.margin}
                                                      onClick={() => logout()}>Logout</ListItem>
                                        </StyledMenuItem>
                                    </StyledMenu>
                                </div>
                            </>)}
                        </Hidden>
                    </Grid>
                </Toolbar>
            </AppBar>
            {children}

        </div>
    );
}

export default Nav;