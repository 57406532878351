import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import qualification from "../Models/qualifikationen";
import DialogContentText from "@material-ui/core/DialogContentText";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const NKrotButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#a80b2a',
        borderColor: '#a80b2a',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);
const NKblauButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#0e4194',
        borderColor: '#0e4194',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);


const useStyles = makeStyles((theme) => ({
    DialogTitlehintergrunde: {
        backgroundColor: '#0e4194'
    },
    DialogTitlefarbe: {
        color: '#FFFFFF'
    }
}));


function QualifikationenErstellen() {
    const classes = useStyles();
    const [Typ, setTyp] = useState(null)
    const [currentQualifikation, setCurrentQualifikation] = useState(null)
    const [qualifikationen, setQualifikationen] = useState([])
    const {getQualification, createQualification, updateQualification, deleteQualifikation} = qualification()
    const [openCreate, setOpenCreate] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)

    useEffect(() => {
        const fetchdata = async () => {
            getQualification().then(quali => {
                setQualifikationen(quali)
            })
        }
        fetchdata()
    }, [qualifikationen])


    function openCreateDialog() {
        setOpenCreate(true)
    }

    function closeCreateDialog() {
        setTyp("")
        setOpenCreate(false)
    }

    function openEditDialog(index) {
        setCurrentQualifikation(index)
        setTyp(index.Typ)
        setOpenEdit(true)

    }

    function closeEditDialog() {
        setTyp(null)
        setOpenEdit(false)
    }

    function openDeleteDialog(index) {
        setCurrentQualifikation(index)
        setOpenDelete(true)
        setTyp(index.Typ)
    }

    function closeDeleteDialog() {
        setOpenDelete(false)
        setTyp(null)
    }


    const onCreateQualifikation = async () => {

        if (Typ == null || Typ === "" || Typ.startsWith(" ")) {

            alert("Typ darf nicht leer sein oder mit einer Leertaste beginnen")
        } else {
            const neueQualifikation = await createQualification(Typ)
            setQualifikationen([...qualifikationen, neueQualifikation])
            closeCreateDialog()
        }

    }
    const onUpdateQualifikation = async () => {
        if (Typ == null || Typ === "" || Typ.startsWith(" ")) {

            alert("Typ darf nicht leer sein oder mit einer Leertaste beginnen")
        } else {
            await updateQualification(currentQualifikation.idQualifikationen, Typ)
            setQualifikationen(alteQualifikationen => alteQualifikationen.map(quali => {
                if (quali.idQualifikationen === currentQualifikation.idQualifikationen) {
                    return {
                        idQualifikationen: currentQualifikation.idQualifikationen,
                        Typ: Typ
                    }
                } else {
                    return quali
                }
            }))
            closeEditDialog()
        }

    }


    const onDeleteVeranstaltung = async () => {
        await deleteQualifikation(currentQualifikation.idQualifikationen)
        setQualifikationen(oldQualifikation => oldQualifikation.filter(quali =>
            currentQualifikation.idQualifikationen !== quali.idQualifikationen))
        closeDeleteDialog()
    }


    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Grid item xs={12}>
                <Typography align={'center'} variant="h4">
                    Qualifikationen erstellen
                </Typography>
                <p/>
            </Grid>
            <Grid item>
                <NKrotButton onClick={openCreateDialog}> Qualifikation erstellen</NKrotButton>
                <p/>
            </Grid>
            <Grid item>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><Typography variant={'h6'}>Qualifikation</Typography></TableCell>
                            <TableCell align="right"><Typography variant={'h6'}>Aktionen</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {qualifikationen.map((row) => (
                            <TableRow key={row.idQualifikationen} hover>
                                <TableCell align="center">{row.Typ}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title={"Bearbeiten"}>
                                        <IconButton onClick={() => {
                                            openEditDialog(row)
                                        }}>
                                            <EditIcon style={{color: '#0e4194'}}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Löschen"}>
                                        <IconButton onClick={() => {
                                            openDeleteDialog(row)
                                        }}>
                                            <DeleteIcon style={{color: '#a80b2a'}}/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Dialog open={openCreate} fullWidth={true}>
                    <div className={classes.DialogTitlehintergrunde}>
                        <DialogTitle className={classes.DialogTitlefarbe}>Qualifikation erstellen</DialogTitle>
                    </div>
                    <DialogContentText align={'center'}>
                        Hier können Sie eine Qualifikation erstellen.
                    </DialogContentText>
                    <DialogContent>
                        <TextField autoFocus margin={"dense"} fullWidth label={"Name*"} value={Typ}
                                   onChange={event => setTyp(event.target.value)}/>
                    </DialogContent>
                    <DialogActions>
                        <NKblauButton onClick={onCreateQualifikation}>Erstellen</NKblauButton>
                        <NKrotButton onClick={closeCreateDialog}>Abbrechen</NKrotButton>
                    </DialogActions>
                </Dialog>
                <Dialog open={openEdit} fullWidth={true}>
                    <div className={classes.DialogTitlehintergrunde}>
                        <DialogTitle className={classes.DialogTitlefarbe}>Qualifikation bearbeiten</DialogTitle>
                    </div>
                    <DialogContentText align={'center'}>
                        Hier können Sie die ausgewählte Qualifikation Umbenennen.
                    </DialogContentText>
                    <DialogContent>
                        <TextField autoFocus margin={"dense"} fullWidth label={"Name*"} value={Typ}
                                   onChange={event => setTyp(event.target.value)}/>
                    </DialogContent>
                    <DialogActions>
                        <NKblauButton onClick={onUpdateQualifikation}>Speichern</NKblauButton>
                        <NKrotButton onClick={closeEditDialog}>Abbrechen</NKrotButton>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDelete} fullWidth={true}>
                    <div className={classes.DialogTitlehintergrunde}>
                        <DialogTitle className={classes.DialogTitlefarbe}>Qualifikation löschen</DialogTitle>
                    </div>
                    <DialogContentText align={'center'}>
                        Wollen sie die ausgewählte Qulifikation wirklich löschen?
                    </DialogContentText>
                    <DialogContent>
                        <TextField autoFocus margin={"dense"} fullWidth label={"Name"} value={Typ}
                                   disabled={true}/>
                    </DialogContent>
                    <DialogActions>
                        <NKblauButton onClick={onDeleteVeranstaltung}>Ja</NKblauButton>
                        <NKrotButton onClick={closeDeleteDialog}>Nein</NKrotButton>
                    </DialogActions>
                </Dialog>


            </Grid>
        </Grid>

    )


}

export default QualifikationenErstellen;