import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import useSession from "./ContextProvider/Session";

function PrivateRoute ({ component: Component, minimumRequiredRole = 4, ...rest }) {
    const {user } = useSession()

    const render = props => {
        if (!user) return <Redirect to={{ pathname: '/', state: { referer: props.location } }}/>
        if (user?.Rolle > minimumRequiredRole) return <Redirect to={{ pathname: '/' }} />
        return <Component {...props} />
    }

    return <Route {...rest} render={render} />
}

export default PrivateRoute