import Axios from "axios";

const mitarbeiter = () => {


    const getMitarbeiter = async () => {
        const response = await Axios.get('/api/Employees')
        return response.data
    }


    const suchMitarbeiter = async (Nachname) => {
        const response = await Axios.get('/api/Employees/search', {
            params: {
                Nachname: Nachname
            }
        })
        return response.data
    }


    const getLogin = async (Email, Passwort) => {

        const response = await Axios.post('/api/Employees/login', {
            Email,
            Passwort,
        })
        return response
    }

    const createMitarbeiter = async (Vorname, Nachname, Adresse, Geschlecht, Wohnort, Email, Passwort, Rolle, FestnetzNummer, Handynummer) => {
        const response = await Axios.post('/api/Employees/add', {
            Vorname,
            Nachname,
            Adresse,
            Geschlecht,
            Wohnort,
            Email,
            Passwort,
            Rolle,
            FestnetzNummer,
            Handynummer
        })
        return response
    }
    const updateMitarbeiterwithPassword = async (idMitarbeiter, Vorname, Nachname, Adresse, Geschlecht, Wohnort, Email, Passwort, Rolle, FestnetzNummer, Handynummer) => {
        await Axios.put(`/api/Employees/${idMitarbeiter}`, {
            Vorname,
            Nachname,
            Adresse,
            Geschlecht,
            Wohnort,
            Email,
            Passwort,
            Rolle,
            FestnetzNummer,
            Handynummer
        })
    }

    const updateProfil = async (idMitarbeiter, Vorname, Nachname, Adresse, Geschlecht, Wohnort, Email, Rolle, FestnetzNummer, Handynummer) => {
        return (await Axios.put(`/api/Employees/updateProfil/${idMitarbeiter}`, {
            Vorname,
            Nachname,
            Adresse,
            Geschlecht,
            Wohnort,
            Email,
            Rolle,
            FestnetzNummer,
            Handynummer
        })).data
    }
    const changePassword = async (idMitarbeiter, Passwort) => {
        await Axios.put(`/api/Employees/changePassword/${idMitarbeiter}`, {
            Passwort
        })

    }

    const updateMitarbeiterwithoutPassword = async (idMitarbeiter, Vorname, Nachname, Adresse, Geschlecht, Wohnort, Email, Rolle, FestnetzNummer, Handynummer) => {
        await Axios.put(`/api/Employees/updateohnePass/${idMitarbeiter}`, {
            Vorname,
            Nachname,
            Adresse,
            Geschlecht,
            Wohnort,
            Email,
            Rolle,
            FestnetzNummer,
            Handynummer
        })

    }
    const deleteMitarbeiter = async (idMitarbeiter) => {
        await Axios.delete(`/api/Employees/${idMitarbeiter}`)
    }
    return {
        getMitarbeiter,
        suchMitarbeiter,
        createMitarbeiter,
        updateMitarbeiterwithPassword,
        updateMitarbeiterwithoutPassword,
        updateProfil,
        changePassword,
        deleteMitarbeiter,
        getLogin
    }


}
export default mitarbeiter