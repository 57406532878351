import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {lighten, makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import request from "../Models/request";
import {format} from "date-fns"
import jobMitarbeiter from "../Models/jobMitarbeiter";
import job from "../Models/job";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Hidden,
    TextField
} from "@material-ui/core";
import Axios from "axios";
import jobQualifikationen from "../Models/jobQualifikationen";
import FormGroup from "@material-ui/core/FormGroup";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";

const NKrotButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#a80b2a',
        borderColor: '#a80b2a',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

const NKgrauButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#888888',
        borderColor: '#888888',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#888888',
            borderColor: '#888888',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#888888',
            borderColor: '#888888',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

const NKblauButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#0e4194',
        borderColor: '#0e4194',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Veranstaltung',
        numeric: false,
        disablePadding: true,
        label: 'Veranstaltung',
    },
    {
        id: 'Job',
        numeric: false, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Job',
    },
    {
        id: 'Beginn',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Beginn',
    },
    {
        id: 'Ende',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Ende',
    },
    {
        id: 'Mitarbeiter',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Mitarbeiter',
    },
    {
        id: 'Datum',
        numeric: true,
        label: 'Datum',
    },

];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        width: '100%',
    },
    DialogTitlehintergrunde: {
        backgroundColor: '#0e4194'
    },
    DialogTitlefarbe: {
        color: '#FFFFFF'
    }


}));

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow style={{backgroundColor: '#0e4194'}}>
                <TableCell align={headCells[0].numeric ? 'right' : 'left'}
                           padding={headCells[0].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[0].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[0].id}
                        direction={orderBy === headCells[0].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[0].id)}
                    >
                        <Typography variant={'h6'}>{headCells[0].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <TableCell align={headCells[1].numeric ? 'right' : 'left'}
                           padding={headCells[1].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[1].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[1].id}
                        direction={orderBy === headCells[1].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[1].id)}
                    >
                        <Typography variant={'h6'}>{headCells[1].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <Hidden xsDown>
                    <TableCell align={headCells[2].numeric ? 'right' : 'left'}
                               padding={headCells[2].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[2].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[2].id}
                            direction={orderBy === headCells[2].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[2].id)}
                        >
                            <Typography variant={'h6'}>{headCells[2].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align={headCells[3].numeric ? 'right' : 'left'}
                               padding={headCells[3].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[3].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[3].id}
                            direction={orderBy === headCells[3].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[3].id)}
                        >
                            <Typography variant={'h6'}>{headCells[3].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                </Hidden>
                <TableCell align={headCells[4].numeric ? 'right' : 'left'}
                           padding={headCells[4].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[4].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[4].id}
                        direction={orderBy === headCells[4].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[4].id)}
                    >
                        <Typography variant={'h6'}>{headCells[4].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <Hidden xsDown>
                    <TableCell align={headCells[5].numeric ? 'right' : 'left'}
                               padding={headCells[5].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[5].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[5].id}
                            direction={orderBy === headCells[5].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[5].id)}
                        >
                            <Typography variant={'h6'}>{headCells[5].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                </Hidden>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {

    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {numSelected} = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    align={"center"}
                >
                    Anfragen von Mitarbeitern
                </Typography>
            )}

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function Anfragen() {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const {getRequest, updateRequest} = request()
    const {updateStatus} = job()
    const [anfragen, setAnfragen] = useState([])
    const [openBearbeiten, setOpenBearbeiten] = useState(false)
    const [currentAnfrage, setCurrentAnfrage] = useState(null)
    const [titel, setTitel] = useState("")
    const [mitarbeiter, setMitarbeiter] = useState(null)
    const [name, setName] = useState("")
    const [Stelle, setStelle] = useState("")
    const [veranstalltung, setVeranstalltung] = useState(null)
    const [currentMitarbeiter, setCurentMitarbeiter] = useState(null)
    const [id, setId] = useState(null)
    const [start, setStart] = useState(null)
    const [ende, setEnde] = useState(null)
    const {createJobEmployee} = jobMitarbeiter()
    const [qualifications, setQualifications] = useState([])
    const {getJobQualification} = jobQualifikationen()
    const [currentJob, setCurrentJob] = useState(null)
    const [checkedQualifikation, setCheckedQualifikation] = useState([])

    function openDialog(index) {
        setCurrentJob(index.Job)
        setCurrentAnfrage(index)
        setCurentMitarbeiter(index.Employee)
        setMitarbeiter(index.Mitarbeiter__ID)
        setTitel(index.Contract.Titel)
        setId(index.Jobs__ID)
        setName(index.Employee.Vorname + " " + index.Employee.Nachname)
        setVeranstalltung(index.Contract)
        setStelle(index.Job.Beschreibung)
        setStart(format(new Date(index.Job.Startzeit), "HH:mm"))
        setEnde(format(new Date(index.Job.Endzeit), "HH:mm"))
        setOpenBearbeiten(true)
    }

    function closeDialog() {
        setOpenBearbeiten(false)
    }

    const onAblehnenAnfrage = async () => {
        await updateRequest(currentAnfrage.idAnfragen, 2)
        setAnfragen(oldAnfragen => oldAnfragen.filter(anfrage =>
            currentAnfrage.idAnfragen !== anfrage.idAnfragen))
        sendMail(2)
        setOpenBearbeiten(false)
    }
    const onAnnehmenAnfrage = async () => {
        await updateRequest(currentAnfrage.idAnfragen, 3)
        await updateStatus(id, 6)
        setAnfragen(oldAnfragen => oldAnfragen.filter(anfrage =>
            currentAnfrage.idAnfragen !== anfrage.idAnfragen))
        await oncreateJobEmployee()
        sendMail(3)
        setOpenBearbeiten(false)
    }

    const oncreateJobEmployee = async () => {
        await createJobEmployee(id, mitarbeiter)

    }


    const fetchQualis = async () => {
        const response = await Axios.get('/api/Qualificationen')
        setQualifications(response.data)
    }


    useEffect(() => {
        const fetchdata = async () => {
            getRequest().then(anfrage => {
                setAnfragen(anfrage)
            })
        }
        fetchdata()
    }, [])


    useEffect(() => {
        if (currentJob != null) {
            getJobQualification(currentJob.idJobs).then(li => {
                setCheckedQualifikation(li.reduce((acc, cur) => [...acc, cur.qualifikationen_ID], []))
            })
        }
    }, [currentJob])


    function sendMail(chase) {

        if (chase === 2) {
            Axios.post('/api/Requests/send', {
                Vorname: currentMitarbeiter.Vorname,
                Nachname: currentMitarbeiter.Nachname,
                Email: currentMitarbeiter.Email,
                betreff: "Anfrage Abgelehnt",
                typ: 2,
                Datum: format(new Date(currentJob.Startzeit), "dd.MM.yyyy"),
                titel: titel,
                Stelle: Stelle,
            }).then(r => {
                console.log(r)
            })


        } else {
            Axios.post('/api/Requests/send', {
                Vorname: currentMitarbeiter.Vorname,
                Nachname: currentMitarbeiter.Nachname,
                Email: currentMitarbeiter.Email,
                betreff: "Arbeitsanfrage bestätigt",
                typ: 3,
                Datum: format(new Date(currentJob.Startzeit), "dd.MM.yyyy"),
                titel: titel,
                Stelle: Stelle,
                Start: start,
                Ende: ende
            }).then(r => {
                console.log(r)
            })

        }


    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"

                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={anfragen.length}
                        />
                        <TableBody>
                            {stableSort(anfragen, getComparator(order, orderBy))
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={row.idAnfragen}
                                            onClick={() => {
                                                fetchQualis()
                                                openDialog(row)
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                padding="none"
                                                style={{padding: 20}}
                                            >
                                                {row.Contract.Titel}
                                            </TableCell>
                                            <TableCell align="left"> {row.Job.Beschreibung}</TableCell>
                                            <Hidden xsDown>
                                                <TableCell
                                                    align="right">{format(new Date(row.Job.Startzeit), "HH:mm")}</TableCell>
                                                <TableCell
                                                    align="right">{format(new Date(row.Job.Endzeit), "HH:mm")}</TableCell>
                                            </Hidden>
                                            <TableCell
                                                align="right">{row.Employee.Vorname + " " + row.Employee.Nachname}</TableCell>
                                            <Hidden xsDown><TableCell
                                                align="right">{format(new Date(row.Datum), "dd.MM.yyyy")}</TableCell>
                                            </Hidden>

                                        </TableRow>
                                    );
                                })}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Dialog open={openBearbeiten} fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Anfrage bearbeiten</DialogTitle>
                </div>
                <DialogContentText align={"center"}>Hier können Sie die gewählte Anfrage bearbeiten</DialogContentText>

                <DialogContent>
                    <TextField disabled={true} label={"Veranstaltungstitel"} value={titel} fullWidth={true}/>
                    <p/>
                    <TextField disabled={true} label={"Mitarbeiter"} value={name} fullWidth={true}/>
                    <p/>
                    <TextField disabled={true} label={"Stelle"} value={Stelle} fullWidth={true}/>
                    <p/>
                    <TextField disabled={true} label={"Startzeit"} value={start} fullWidth={true}/>
                    <p/>
                    <TextField disabled={true} label={"Endzeit"} value={ende} fullWidth={true}/>

                    <FormGroup>
                        Ausgewählte Qualifikationen
                        <List dense className={classes.userList}>
                            {qualifications?.filter(Quali => checkedQualifikation.indexOf(Quali.idQualifikationen) >= 0).map((quali, index) => (
                                    <ListItem key={index} button>
                                        <ListItemText id={index} primary={quali.Typ}/>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                checked={checkedQualifikation.indexOf(quali.idQualifikationen) !== -1}
                                                inputProps={{'aria-labelledby': index}}
                                                disabled={false}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ) || <Button/>
                            )}
                        </List>
                        <p/>
                    </FormGroup>


                </DialogContent>
                <DialogActions>
                    <NKblauButton variant={"outlined"} onClick={onAnnehmenAnfrage}>Annehmen</NKblauButton>
                    <NKrotButton variant={"outlined"} onClick={onAblehnenAnfrage}>Ablehnen</NKrotButton>
                    <NKgrauButton variant={"outlined"} onClick={closeDialog}>Schließen</NKgrauButton>
                </DialogActions>


            </Dialog>
        </div>
    );
}