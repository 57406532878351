import React, {useEffect, useState} from 'react'

import {Calendar, momentLocalizer, Views} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {Grid} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {KeyboardDatePicker} from "@material-ui/pickers";
import veranstaltung from "../Models/veranstaltung";
import Session from "../ContextProvider/Session";
import {makeStyles, withStyles} from "@material-ui/core/styles";

const NkrotButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#a80b2a',
        borderColor: '#a80b2a',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },

    },
})(Button);

const NkblauButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#0e4194',
        borderColor: '#0e4194',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
        },

    },
})(Button);

const Abbrechencheck = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#51565c',
        borderColor: '#51565c',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#51565c',
            borderColor: '#51565c',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#51565c',
            borderColor: '#b05b5b',
        },

    },
})(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    DialogTitlehintergrunde: {
        backgroundColor: '#0e4194'
    },
    DialogTitlefarbe: {
        color: '#FFFFFF'
    }

}));

const MyCalendar = () => {

    //const
    const {user} = Session()
    const classes = useStyles();
    const localizer = momentLocalizer(moment)
    let allViews = Object.keys(Views).map(k => Views[k])
    const [openCreate, setOpenCreate] = useState(false)
    const [openEdit, setopenEdit] = useState(false)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [Beschreibung, setBeschreibung] = useState(null)
    const [Datum, setDatum] = useState(new Date())
    const [title, setTitle] = useState(null)
    const {getVeranstaltungforEvents, createVeranstaltung, updateVeranstaltung, deleteVeranstaltung} = veranstaltung()
    const [veranstaltungen, setVeranstaltungen] = useState([])
    const [currentVeranstaltung, setCurrentVeranstalltung] = useState(null)
    const [openRead, setopenRead] = useState(false)

    const messages = {
        allDay: 'Ganzer Tag',
        previous: '<',
        next: '>',
        today: 'Heute',
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
        work_week: 'Arbeitswoche',
        agenda: 'Agenda',
        date: 'Datum',
        time: 'Uhrzeit',
        event: 'Ereignis',
        showMore: total => `+ zeige Mehr (${total})`
    }
    //formats customized

    moment.locale('de'
    )
    moment.updateLocale('de', {
        months: [
            'Januar', 'Febuar', 'März', 'April', 'Mai', 'Juni', 'Juli',
            'August', 'September', 'Oktober', 'November', 'Dezember'],
        monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul',
            'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],

    })
    moment.locale('de', {
        week: {
            dow: 1
        }
    })

    useEffect(() => {
        getVeranstaltungforEvents().then(veranstaltung => {
            setVeranstaltungen(veranstaltung)
        })

    }, [veranstaltungen])

    const onCreateVeranstaltung = async () => {

        if (title == null || title === "" || title.startsWith(" ") || Beschreibung == null || Beschreibung === "" || Beschreibung.startsWith(" ") ||
            startDate === null || endDate == null || Datum == null) {
            alert("Eins der PlichtFelder ist leer oder hat eine unzuläsige eingabe z.B Leertaste")
        } else {
            if (endDate <= startDate) {
                setEndDate(endDate.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1))
            }
            const neueVeranstaltung = await createVeranstaltung(title, Beschreibung, Datum, startDate, endDate)
            setVeranstaltungen([...veranstaltungen, neueVeranstaltung])
            closeCreateDialog()
        }
    }

    const onUpdateVeranstaltung = async () => {


        if (title == null || title === "" || title.startsWith(" ") || Beschreibung == null || Beschreibung === "" || Beschreibung.startsWith(" ") ||
            startDate === null || endDate == null || Datum == null) {
            alert("Eins der PlichtFelder ist leer oder hat eine unzuläsige eingabe z.B Leertaste")
        } else {
            if (endDate <= startDate) {
                setEndDate(endDate.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1))
            }

            await updateVeranstaltung(currentVeranstaltung.idVeranstaltungen, title, Beschreibung, Datum, startDate, endDate)
            setVeranstaltungen(alteVeranstaltung => alteVeranstaltung.map(ver => {
                if (ver.idVeranstaltungen === currentVeranstaltung.idVeranstaltungen) {
                    return {
                        idVeranstaltungen: currentVeranstaltung.idVeranstaltungen,
                        title: title,
                        Beschreibung: Beschreibung,
                        Datum: Datum,
                        Start: startDate,
                        End: endDate,
                        allday: false,
                    }
                } else {
                    return ver
                }
            }))
            closeEditDialog()

        }


    }

    const onDeleteVeranstaltung = async () => {


        await deleteVeranstaltung(currentVeranstaltung.idVeranstaltungen)
        setVeranstaltungen(oldVeranstaltung => oldVeranstaltung.filter(veranstaltung =>
            currentVeranstaltung.idVeranstaltungen !== veranstaltung._id))
        setopenEdit(false)

    }


    function openCreateDialog(s) {
        //Function to open CreateDialog
        setOpenCreate(true)
        setDatum(s.start)
        setStartDate(new Date(s.start))
        setEndDate(new Date(s.end))
    }


    function closeCreateDialog() {
        //Function to close CreateDialog
        setOpenCreate(false)
        setTitle(null)
        setDatum(null)
        setBeschreibung(null)
        setStartDate(null)
        setEndDate(null)


    }

    function openEditDialog(s) {
        setCurrentVeranstalltung(s)
        setopenEdit(true)
        setTitle(s.title)
        setBeschreibung(s.Beschreibung)
        setDatum(new Date(s.Datum))
        setStartDate(new Date(s.start))
        setEndDate(new Date(s.end))
    }

    function closeEditDialog() {
        setopenEdit(false)
        setTitle(null)
        setBeschreibung(null)
    }

    function openReadDialog(s) {
        setopenRead(true)
        setTitle(s.title)
        setBeschreibung(s.Beschreibung)
        setDatum(new Date(s.Datum))
        setStartDate(s.start)
        setEndDate(s.end)
    }

    function closeReadDialog() {
        setopenRead(false)
    }


    return (
        <Grid container direction={'column'} justify={'center'} alignItems={'center'} alignContent={'center'}>
            <Calendar messages={messages}
                      selectable
                      localizer={localizer}
                      views={allViews}
                      events={veranstaltungen}
                      onSelectEvent={event => {
                          if (user.Rolle === 1 || user.Rolle === 2) openEditDialog(event)
                          else openReadDialog(event)
                      }}
                      onSelectSlot={(slotInfo) => {
                          if (user.Rolle === 1 || user.Rolle === 2) openCreateDialog(slotInfo)

                      }}
                      startAccessor="start"
                      endAccessor="end"
                      style={{height: 800, width: 1300}}

            />
            <Dialog open={openCreate} onClose={closeCreateDialog} aria-labelledby="form-dialog-title">
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle id="form-dialog-title" className={classes.DialogTitlefarbe}>Termin
                        plannen</DialogTitle>
                </div>

                <DialogContent>
                    <DialogContentText>
                        Um einen Termin zu erstellen tragen Sie bitte die notwendigen Informationen ein.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Titel"
                        value={title}
                        onChange={event => setTitle(event.target.value)}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Beschreibung"
                        value={Beschreibung}
                        onChange={event => setBeschreibung(event.target.value)}
                        fullWidth
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Datum"
                        value={Datum}
                        onChange={setDatum}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Start"
                        value={startDate}
                        onChange={setStartDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Ende"
                        value={endDate}
                        onChange={setEndDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <NkblauButton onClick={onCreateVeranstaltung} color="#FFFFFF">Erstellen</NkblauButton>
                    <NkrotButton onClick={closeCreateDialog} color='#FFFFFF'>Abbrechen</NkrotButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} onClose={closeEditDialog}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle id="form-dialog-title" className={classes.DialogTitlefarbe}>Termin
                        bearbeiten</DialogTitle>
                </div>
                <DialogContent>
                    <DialogContentText>Hier können Sie die Veranstalltung bearbeiten oder löschen</DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Titel"
                        value={title}
                        onChange={event => setTitle(event.target.value)}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Beschreibung"
                        value={Beschreibung}
                        onChange={event => setBeschreibung(event.target.value)}
                        fullWidth
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Datum"
                        value={Datum}
                        onChange={setDatum}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Start"
                        value={startDate}
                        onChange={setStartDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Ende"
                        value={endDate}
                        onChange={setEndDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />

                </DialogContent>
                <DialogActions>
                    <NkblauButton onClick={onUpdateVeranstaltung}>Update</NkblauButton>
                    <NkrotButton onClick={onDeleteVeranstaltung}>Löschen</NkrotButton>
                    <Abbrechencheck onClick={closeEditDialog}>Abbrechen</Abbrechencheck>
                </DialogActions>
            </Dialog>


            <Dialog open={openRead} onClose={closeReadDialog}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle id="form-dialog-title" className={classes.DialogTitlefarbe}>Termin
                        einsehen</DialogTitle>
                </div>
                <DialogContent>
                    <DialogContentText>Hier können Sie die Veranstalltung einsehen</DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Titel"
                        value={title}
                        disabled={true}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Beschreibung"
                        value={Beschreibung}
                        multiline={true}
                        disabled={true}
                        fullWidth
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Datum"
                        value={Datum}
                        onChange={setDatum}
                        disabled={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Start"
                        value={startDate}
                        disabled={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Ende"
                        value={endDate}
                        disabled={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />

                </DialogContent>
                <DialogActions>
                    <NkrotButton onClick={closeReadDialog}>Abbrechen</NkrotButton>
                </DialogActions>
            </Dialog>


        </Grid>


    )
}

export default MyCalendar