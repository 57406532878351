import axios from "axios";

const qualification = () => {


    const getQualification = async () => {
        const response = await axios.get('/api/Qualificationen')
        return response.data
    }



    const createQualification = async (Typ) => {
        const response = await axios.post('/api/Qualificationen/add', {
            Typ
        })
        return response
    }
    const updateQualification = async (idQualifikationen,Typ) => {
        await axios.put(`/api/Qualificationen/${idQualifikationen}`, {
            Typ
        })

    }
    const deleteQualifikation = async (idQualifikationen) => {
        await axios.delete(`/api/Qualificationen/${idQualifikationen}`)
    }

    return {getQualification, createQualification, updateQualification, deleteQualifikation}

}
export default qualification