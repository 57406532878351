import React, {useState, useEffect} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {lighten, makeStyles, withStyles} from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, InputBase, List, MenuItem, Select,
    TextField,
    Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import mitarbeiter from "../Models/mitarbeiter";
import jobMitarbeiter from "../Models/jobMitarbeiter";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const BootstrapButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#a80b2a',
        borderColor: '#a80b2a',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },

    },
})(Button);
const NkblauButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#0e4194',
        borderColor: '#0e4194',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
        },

    },
})(Button);

const headCells = [
    {
        id: 'Nachname',
        numeric: false,
        disablePadding: true,
        label: 'Nachname',
    },
    {
        id: 'Vorname',
        numeric: false, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Vorname',
    },
    {
        id: 'Aktionen',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Aktionen',
    }
];


const useStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        width: '100%',
    },
    monthButton: {
        height: 36,
        width: 100
    },
    Toolbarroot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },

    DialogTitlehintergrunde: {
        backgroundColor: '#0e4194'
    },
    DialogTitlefarbe: {
        color: '#FFFFFF'
    }


}));

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow style={{backgroundColor: '#0e4194'}}>
                <TableCell align={headCells[0].numeric ? 'right' : 'left'}
                           padding={headCells[0].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[0].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[0].id}
                        direction={orderBy === headCells[0].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[0].id)}
                    >
                        <Typography variant={'h6'}>{headCells[0].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <TableCell align={headCells[1].numeric ? 'right' : 'left'}
                           padding={headCells[1].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[1].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[1].id}
                        direction={orderBy === headCells[1].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[1].id)}
                    >
                        <Typography variant={'h6'}>{headCells[1].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <TableCell align={headCells[2].numeric ? 'right' : 'left'}
                           padding={headCells[2].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[2].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[2].id}
                        direction={orderBy === headCells[2].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[2].id)}
                    >
                        <Typography variant={'h6'}>{headCells[2].label}</Typography>
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {

    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function Buchhaltung() {
    const classes = useStyle();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const {getMitarbeiter, suchMitarbeiter} = mitarbeiter()
    const [liste, setListe] = useState([])
    const {getJobperMonth} = jobMitarbeiter()
    const [currentMitarbeiter, setCurrentMitarbeiter] = useState(null)
    const [dialog, setDialog] = useState(false)
    const [mitarbeiterjobListe, setMitarbeiterjobliste] = useState([])
    const [monatsverdienst, setmonatsverdienst] = useState("")
    const [monatsarbeitszeit, setmonatsarbeitszeit] = useState("")
    const [jahressverdienst, setJahresverdienst] = useState("")
    const [jahressarbeitszeit, setJahressarbeitszeit] = useState("0")
    const [Monatsuebericht, setMonatsuebersicht] = useState(false)
    const [jahresUebersicht, setJahresUebersicht] = useState(false)
    const [jahr, setJahr] = useState(2020)
    const [suche, setSuche] = useState(null)

    useEffect(() => {
        const fetchdata = async () => {
            getMitarbeiter().then(liste => {
                setListe(liste)
            })
        }
        fetchdata()
    }, [])

    useEffect(() => {
        if (suche != null) {
            suchMitarbeiter(suche).then(response => {
                setListe(response)
            })
        }

    }, [suche])

    function Gesamtlohnberechnung(x, liste) {

        let verdienst = 0
        if (x === 1) {
            for (let jobs of liste) {

                let jobverdienst = 0
                jobverdienst = ((((new Date(jobs.Job.Endzeit) - new Date(jobs.Job.Startzeit)) / 1000 / 60) - jobs.Job.Pausenzeit) * (jobs.Job.Stundenlohn / 60)) + jobs.Job.Zuschlag
                verdienst = verdienst + jobverdienst
            }
            verdienst = Math.round(verdienst * 100) / 100 + " €"
            setmonatsverdienst(verdienst)

        } else {
            for (let jobs of liste) {
                let jobverdienst = 0
                jobverdienst = ((((new Date(jobs.Job.Endzeit) - new Date(jobs.Job.Startzeit)) / 1000 / 60) - jobs.Job.Pausenzeit) * (jobs.Job.Stundenlohn / 60)) + jobs.Job.Zuschlag
                verdienst = verdienst + jobverdienst
            }
            verdienst = Math.round(verdienst * 100) / 100 + " €"
            setJahresverdienst(verdienst)
        }

    }

    function GesammtStundenzeitrechnung(x, liste) {
        let gesamtzeit = 0
        if (x === 1) {
            for (let jobs of liste) {
                gesamtzeit = gesamtzeit + ((((new Date(jobs.Job.Endzeit) - new Date(jobs.Job.Startzeit)) / 1000) / 60) - jobs.Job.Pausenzeit)
            }
            let hours = Math.floor(gesamtzeit / 60)
            let min = Math.floor(gesamtzeit % 60)
            setmonatsarbeitszeit(hours + "h " + min + " m")
        } else {
            console.log("ich bin hier")
            for (let jobs of liste) {
                gesamtzeit = gesamtzeit + ((((new Date(jobs.Job.Endzeit) - new Date(jobs.Job.Startzeit)) / 1000) / 60) - jobs.Job.Pausenzeit)
            }

            let hours = Math.floor(gesamtzeit / 60)
            let min = Math.floor(gesamtzeit % 60)
            setJahressarbeitszeit(hours + "h " + min + " m")
        }
    }

    function openDialog() {
        setDialog(true)

    }

    function closeDialog() {
        setCurrentMitarbeiter(null)
        setDialog(false)
        setJahr(2020)
        if (Monatsuebericht === true) {
            setMonatsuebersicht(false)
        } else {
            setJahresUebersicht(false)
        }
        setJahressarbeitszeit("0")
        setmonatsarbeitszeit("0")
        setJahresverdienst("0")
        setmonatsverdienst("0")


    }

    function closeMonatsübersicht() {

        setCurrentMitarbeiter(null)
        setMonatsuebersicht(false)
    }

    function openMonatsübersicht(index) {
        setCurrentMitarbeiter(index)
        setMonatsuebersicht(true)

    }

    function openJahresUebersicht(index) {
        setCurrentMitarbeiter(index)
        setJahresUebersicht(true)
    }

    function closeJahresuebersicht() {
        setCurrentMitarbeiter(null)
        setJahresUebersicht(false)
    }

    const onChooseMonth = async (month) => {

        const untergrenze = new Date()
        let obergrenze = new Date()
        let response
        switch (month) {
            case "Januar":
                untergrenze.setFullYear(jahr, 0, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 1, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;
            case "Februar":
                untergrenze.setFullYear(jahr, 1, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 2, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;
            case "März":
                untergrenze.setFullYear(jahr, 2, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 3, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;
            case "April":
                untergrenze.setFullYear(jahr, 3, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 4, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;
            case "Mai":
                untergrenze.setFullYear(jahr, 4, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 5, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;
            case "Juni":

                untergrenze.setFullYear(jahr, 5, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 6, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;
            case "Juli":
                untergrenze.setFullYear(jahr, 6, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 7, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;
            case "August":
                untergrenze.setFullYear(jahr, 7, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 8, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;
            case "September":
                untergrenze.setFullYear(jahr, 8, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 9, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;

            case "Oktober":
                untergrenze.setFullYear(jahr, 9, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 10, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;

            case "November":
                untergrenze.setFullYear(jahr, 10, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr, 11, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;
            case "Dezember":
                untergrenze.setFullYear(jahr, 11, 1)
                untergrenze.setHours(0, 0, 0, 0)
                obergrenze.setFullYear(jahr + 1, 0, 1)
                obergrenze.setHours(0, 0, 0, 0)
                response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, untergrenze, obergrenze)
                setMitarbeiterjobliste(response)
                Gesamtlohnberechnung(1, response)
                GesammtStundenzeitrechnung(1, response)
                break;

            default:
                // Anweisungen werden ausgeführt,
                // falls keine der case-Klauseln mit expression übereinstimmt
                break;
        }
        openDialog()


    }

    const onChooseYear = async (year) => {
        let date1 = new Date()
        date1.setFullYear(year.toString(), 0, 1)
        date1.setHours(0, 0, 0, 0)
        let date2 = new Date()
        date2.setFullYear(date1.getFullYear() + 1, 0, 1)
        date2.setHours(0, 0, 0, 0)
        const response = await getJobperMonth(currentMitarbeiter.idMitarbeiter, date1, date2)
        if (jahresUebersicht === true) {
            setMitarbeiterjobliste(response)
        }
        Gesamtlohnberechnung(2, response)
        GesammtStundenzeitrechnung(2, response)
        openDialog()
    }
    const jobListe = []
    for (let mit of mitarbeiterjobListe) {
        jobListe.push(
            <ListItem key={mit.Jobs_ID} button>
                <ListItemText>{mit.Job.Beschreibung}</ListItemText>
            </ListItem>
        )
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (
        <Grid container direction="column" justify="center" alignItems="center">
            <Paper className={classes.paper}>
                <Toolbar className={classes.Toolbarroot}>
                    <Typography
                        className={classes.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        align={"center"}
                    >
                        Buchhaltung
                    </Typography>
                    <InputBase
                        placeholder="Suchen(Nachname)"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        value={suche}
                        onChange={e => setSuche(e.target.value)}
                        inputProps={{'aria-label': 'search'}}
                    />
                </Toolbar>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={liste.length}
                        />
                        <TableBody>
                            {stableSort(liste, getComparator(order, orderBy))
                                .map((row) => {
                                    return (
                                        <TableRow key={row.idMitarbeiter}
                                                  hover>
                                            <TableCell align="left">{row.Nachname}</TableCell>
                                            <TableCell align="left">{row.Vorname}</TableCell>
                                            <TableCell align={"right"}>
                                                <Tooltip title={"Jahres und Monats Auswahl"}>
                                                    <IconButton onClick={() => {
                                                        openMonatsübersicht(row)
                                                    }}>
                                                        <DateRangeIcon fontSize={"small"}/>
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title={"Vollesjahr Auswahl"}>
                                                    <IconButton onClick={() => {
                                                        openJahresUebersicht(row)
                                                    }}>
                                                        <DateRangeIcon fontSize={"small"}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog open={dialog}>
                    <DialogContent>
                        <TextField label={"Gesamt Jobs"} disabled={true} value={mitarbeiterjobListe.length}
                                   variant={"outlined"} fullWidth={true}/>
                        <br/>
                        <br/>
                        <List>{jobListe}</List>
                        <p/>
                        <div>
                            <TextField label={"Gesammt Verdienst des Monats"} disabled={true} value={monatsverdienst}
                                       variant={"outlined"}/>
                            <TextField label="Gesammt stunden des Monats" disabled={true}
                                       value={monatsarbeitszeit}
                                       variant={"outlined"}/>
                        </div>
                        <p/>

                        <div>
                            <TextField label={"Gesammt Verdienst des Jahres"} disabled={true} value={jahressverdienst}
                                       variant={"outlined"}/>
                            <TextField label="Gesammt stunden des jahres" disabled={true}
                                       value={jahressarbeitszeit}
                                       variant={"outlined"}/>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <BootstrapButton onClick={closeDialog}>Schließen</BootstrapButton>
                    </DialogActions>
                </Dialog>
                <Dialog open={Monatsuebericht}>
                    <div className={classes.DialogTitlehintergrunde}>
                        <DialogTitle className={classes.DialogTitlefarbe}> Monats und Jahres übersicht</DialogTitle>
                    </div>
                    <DialogContentText align={"center"}> Jahr und Monat wählen</DialogContentText>
                    <DialogContent>
                        <Select value={jahr}
                                onChange={e => {
                                    setJahr(e.target.value)
                                }}>
                            <MenuItem value={2020}>2020</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2024}>2024</MenuItem>
                            <MenuItem value={2025}>2025</MenuItem>
                            <MenuItem value={2026}>2026</MenuItem>
                            <MenuItem value={2027}>2027</MenuItem>
                            <MenuItem value={2028}>2028</MenuItem>
                            <MenuItem value={2029}>2029</MenuItem>
                            <MenuItem value={2030}>2030</MenuItem>
                        </Select>
                        <p/>
                        <Typography variant={"h6"}>Quartal 1</Typography>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("Januar")
                            onChooseYear(jahr)

                        }}>Januar</Button>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("Februar")
                            onChooseYear(jahr)
                        }}>Februar</Button>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("März")
                            onChooseYear(jahr)
                        }}>März</Button>
                        <p/>
                        <Typography variant={"h6"}>Quartal 2</Typography>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("April")
                            onChooseYear(jahr)
                        }}>April</Button>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("Mai")
                            onChooseYear(jahr)
                        }}>Mai</Button>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("Juni")
                            onChooseYear(jahr)
                        }}>Juni</Button>
                        <p/>
                        <Typography variant={"h6"}>Quartal 3</Typography>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("Juli")
                            onChooseYear(jahr)
                        }}>Juli</Button>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("August")
                            onChooseYear(jahr)
                        }}>August</Button>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("September")
                            onChooseYear(jahr)
                        }}>September</Button>
                        <p/>
                        <Typography variant={"h6"}>Quartal 4</Typography>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("Oktober")
                            onChooseYear(jahr)
                        }}>Oktober</Button>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("November")
                            onChooseYear(jahr)
                        }}>November</Button>
                        <Button className={classes.monthButton} variant={"outlined"} onClick={() => {
                            onChooseMonth("Dezember")
                            onChooseYear(jahr)
                        }}>Dezember</Button>
                    </DialogContent>
                    <DialogActions>
                        <BootstrapButton onClick={closeMonatsübersicht}>Schließen</BootstrapButton>
                    </DialogActions>
                </Dialog>
                <Dialog open={jahresUebersicht}>

                    <div className={classes.DialogTitlehintergrunde}>
                        <DialogTitle className={classes.DialogTitlefarbe}> Hier können sie ein Jahr
                            auswälhen</DialogTitle>
                    </div>

                    <DialogContent>
                        <Select value={jahr}
                                onChange={e => {
                                    setJahr(e.target.value)
                                }}>
                            <MenuItem value={2020}>2020</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2024}>2024</MenuItem>
                            <MenuItem value={2025}>2025</MenuItem>
                            <MenuItem value={2026}>2026</MenuItem>
                            <MenuItem value={2027}>2027</MenuItem>
                            <MenuItem value={2028}>2028</MenuItem>
                            <MenuItem value={2029}>2029</MenuItem>
                            <MenuItem value={2030}>2030</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <NkblauButton onClick={() => {
                            onChooseYear(jahr)
                        }}>Bestätigen</NkblauButton>
                        <BootstrapButton onClick={closeJahresuebersicht}>Schließen</BootstrapButton>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Grid>
    );
}

export default Buchhaltung;