import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Session from "../ContextProvider/Session";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import mitarbeiter from "../Models/mitarbeiter";


const NKrotButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#a80b2a',
        borderColor: '#a80b2a',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

const NKgrauButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#888888',
        borderColor: '#888888',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#888888',
            borderColor: '#888888',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#888888',
            borderColor: '#888888',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

const NKblauButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#0e4194',
        borderColor: '#0e4194',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.spacing(60),
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    divider: {
        width: "5px",
        height: "auto",
        display: "inline-block"
    },
    DialogTitlehintergrunde: {
        backgroundColor: '#0e4194'
    },
    DialogTitlefarbe: {
        color: '#FFFFFF'
    }

}));

function Meinprofil() {
    const {user, setUser} = Session()
    const [Vorname, setVorname] = useState(user?.Vorname)
    const [Nachname, setNachname] = useState(user?.Nachname)
    const [Adresse, setAdresse] = useState(user?.Adresse)
    const [Wohnort, setWohnort] = useState(user?.Wohnort)
    const [email, setEmail] = useState(user?.Email)
    const [pass1, setPass1] = useState("")
    const [pass2, setPass2] = useState("")
    const [openEdit, setOpenEdit] = useState(false)
    const [passDialog, setPassDialog] = useState(false)
    const {updateProfil, changePassword} = mitarbeiter()

    const classes = useStyles();

    function openEditDialog() {
        setOpenEdit(true)
    }

    function closeEditDialog() {

        setOpenEdit(false)
    }

    function openPassDialog() {
        setPassDialog(true)
    }

    function closePassDialog() {
        setPass1("")
        setPass2("")
        setPassDialog(false)
    }

    const onUpdateProfil = async () => {
        if (Vorname === "" || Vorname.startsWith(" ") || Nachname === "" || Nachname.startsWith(" ")
            || Adresse === "" || Adresse.startsWith(" ") || Wohnort === "" || Wohnort.startsWith(" ")
            || email === "" || email.startsWith(" ")) {
            alert("Ein PlichtFeld ist leer oder fängt mit einer Leertaste an.")
        } else {
            const response = await updateProfil(user?.idMitarbeiter, Vorname, Nachname, Adresse,
                user?.Geschlecht, Wohnort, email, user?.Rolle, user?.FestnetzNummer, user?.Handynummer)
            setUser(response)
            closeEditDialog()
        }


    }

    const onChangePassword = async () => {

        if (pass1 === pass2 && pass1.length > 0) {
            await changePassword(user?.idMitarbeiter, pass1)
            closePassDialog()
        } else {
            alert("Passwort passen nicht überein oder eins der felder ist leer")
        }

    }

    return (
        <Grid id='grid' container alignItems={'center'} justify={'center'}>
            <Grid item xs={12}>
                <Typography align={'center'} variant="h4">
                    Profil
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
                <Paper className={classes.paper}>
                    <Typography className={classes.flex} variant="h6">
                        Eigene Angaben
                    </Typography>
                    <p/>
                    <TextField disabled={true} label='Vorname' value={Vorname}/>
                    <p/>
                    <TextField disabled={true} label='Name' value={Nachname}/>
                    <p/>
                    <TextField disabled={true} label='Adresse' value={Adresse}/>
                    <p/>
                    <TextField disabled={true} label='Wohnort' value={Wohnort}/>
                    <p/>
                    <TextField disabled={true} label='Email' value={email}/>
                    <p/>
                    <NKblauButton variant='contained' onClick={() => openEditDialog()}>Bearbeiten</NKblauButton>
                    <div className={classes.divider}/>
                    <NKblauButton variant='contained' onClick={() => openPassDialog()}>Passwort ändern</NKblauButton>
                </Paper>
            </Grid>

            <Dialog open={openEdit} aria-labelledby="form-dialog-title" fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}> Profil bearbeiten</DialogTitle>
                </div>
                <DialogContentText align={"center"}> Hier können Sie ihre Daten bearbeiten</DialogContentText>
                <DialogContent>
                    <TextField label={"Vorname*"} value={Vorname}
                               onChange={event => setVorname(event.target.value)} fullWidth={true}/>
                    <p/>
                    <TextField label={"Nachname*"} value={Nachname}
                               onChange={event => setNachname(event.target.value)} fullWidth={true}/>
                    <p/>
                    <TextField label={"Adresse*"} value={Adresse}
                               onChange={event => setAdresse(event.target.value)} fullWidth={true}/>
                    <p/>
                    <TextField label={"Wohnort*"} value={Wohnort}
                               onChange={event => setWohnort(event.target.value)} fullWidth={true}/>
                    <p/>
                    <TextField label={"Email*"} value={email}
                               onChange={event => setEmail(event.target.value)} fullWidth={true}/>

                </DialogContent>
                <DialogActions>
                    <NKblauButton variant={"outlined"} onClick={onUpdateProfil}> Speichern</NKblauButton>

                    <NKrotButton variant={"outlined"} onClick={() => closeEditDialog()}>Abbrechen</NKrotButton>

                </DialogActions>

            </Dialog>
            <Dialog open={passDialog} aria-labelledby="form-dialog-title" fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}> Passwort Ändern</DialogTitle>
                </div>
                <DialogContentText align={"center"}> Hier können Sie ihr Passwort ändern</DialogContentText>
                <DialogContent>
                    <p/>
                    <TextField label={"neues Passwort"} value={pass1}
                               onChange={event => setPass1(event.target.value)} fullWidth={true}/>
                    <p/>
                    <TextField label={"Passwort wiederholen"} value={pass2}
                               onChange={event => setPass2(event.target.value)} fullWidth={true}/>
                    <p/>
                </DialogContent>
                <DialogActions>
                    <NKblauButton variant={"outlined"} onClick={onChangePassword}> Speichern</NKblauButton>

                    <NKrotButton variant={"outlined"} onClick={closePassDialog}>Abbrechen</NKrotButton>
                </DialogActions>
            </Dialog>
        </Grid>
    )

}

export default Meinprofil;
