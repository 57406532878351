import React, {useState, useEffect} from "react";
import {format} from "date-fns"
import useSession from "../ContextProvider/Session";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Hidden,
    TextField,
    Typography
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import jobMitarbeiter from "../Models/jobMitarbeiter";
import {lighten, makeStyles, withStyles} from "@material-ui/core/styles";
import veranstaltung from "../Models/veranstaltung";
import WorkIcon from '@material-ui/icons/Work';
import EventIcon from '@material-ui/icons/Event';
import IconButton from '@material-ui/core/IconButton';
import {KeyboardDatePicker} from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const NKrotButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#a80b2a',
        borderColor: '#a80b2a',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        width: "100%",
    },
    DialogTitlehintergrunde: {
        backgroundColor: '#0e4194'
    },
    DialogTitlefarbe: {
        color: '#FFFFFF'
    }


}));

const headCells = [
    {
        id: 'Beschreibung',
        numeric: false,
        disablePadding: true,
        label: 'Job',
    },
    {
        id: 'Datum',
        numeric: true,
        label: 'Datum',
    },
    {
        id: 'Startzeit',
        numeric: true,
        label: 'Beginn',
    },
    {
        id: 'Endzeit',
        numeric: true,
        label: 'Ende'
    },
    {
        id: 'Infos',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Infos',
    },

];

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow style={{backgroundColor: '#0e4194'}}>
                <TableCell align={headCells[0].numeric ? 'right' : 'left'}
                           padding={headCells[0].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[0].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[0].id}
                        direction={orderBy === headCells[0].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[0].id)}
                    >
                        <Typography variant={'h6'}>{headCells[0].label}</Typography>
                    </TableSortLabel>
                </TableCell>

                <TableCell align={headCells[1].numeric ? 'right' : 'left'}
                           padding={headCells[1].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[1].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[1].id}
                        direction={orderBy === headCells[1].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[1].id)}
                    >
                        <Typography variant={'h6'}>{headCells[1].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <Hidden xsDown>
                    <TableCell align={headCells[2].numeric ? 'right' : 'left'}
                               padding={headCells[2].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[2].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[2].id}
                            direction={orderBy === headCells[2].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[2].id)}
                        >
                            <Typography variant={'h6'}>{headCells[2].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align={headCells[3].numeric ? 'right' : 'left'}
                               padding={headCells[3].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[3].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[3].id}
                            direction={orderBy === headCells[3].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[3].id)}
                        >
                            <Typography variant={'h6'}>{headCells[3].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                </Hidden>
                <TableCell align={headCells[4].numeric ? 'right' : 'left'}
                           padding={headCells[4].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[4].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[4].id}
                        direction={orderBy === headCells[4].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[4].id)}
                    >
                        <Typography variant={'h6'}>{headCells[4].label}</Typography>
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {

    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {numSelected} = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    align={"center"}
                >
                    Vergangene Jobs
                </Typography>
            )}

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


function VergangendeJobs() {
    const classes = useStyles()
    const {user} = useSession()
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Job');
    const [myJobs, setMyJobs] = useState([])
    const [veranstaltungen, setVEranstaltungen] = useState([])
    const {postVeranstaltungofEmployee} = veranstaltung()
    const {getMyOldJobs} = jobMitarbeiter()
    //Dialog Variablen
    const [veranstaltungsDialog, setOpenVeranstaltungsDialog] = useState(false)
    const [jobDialog, setOpenJobDialog] = useState(false)

    //Veranstaltungs Variablen
    const [veranstaltungsTitel, setveranstaltungsTitel] = useState(null)
    const [veranstaltunsDatum, setVEranstaltungsDatum] = useState(null)
    const [veranstaltunsStart, setVEranstaltungsStart] = useState(null)
    const [veranstaltunsEnde, setVEranstaltungsEnde] = useState(null)
    const [veranstaltunsBeschreibung, setVEranstaltungsBeschreibung] = useState(null)

    //Job Variablen
    const [jobBeschreibung, setJobBeschreibung] = useState(null)
    const [jobEndzeit, setJobEndzeit] = useState(null)
    const [jobStartzeit, setJobStartzeit] = useState(null)
    const [jobPausenzeit, setJobPausenzeit] = useState(null)
    const [jobStundenlohn, setJobStundenlohn] = useState(null)
    const [jobZuschlag, setJobZuschlag] = useState(null)

    useEffect(() => {
        let testlsite = []
        const fetchdata = async () => {
            getMyOldJobs(user.idMitarbeiter).then(liste => {
                for (let v of liste) {
                    testlsite.push(v.Job.VeranstaltungsID)
                }
                setVEranstaltungen(testlsite)
                setMyJobs(liste)
                console.log(liste)
            })
        }
        fetchdata()
    }, [])


    useEffect(() => {
        const fetchVeranstaltungen = async () => {
            postVeranstaltungofEmployee(veranstaltungen).then(li => {
                setVEranstaltungen(li)
            })

        }
        fetchVeranstaltungen()
    }, [myJobs])


    function openJobDialog(index) {
        setJobBeschreibung(index.Beschreibung)
        setJobStartzeit(index.Startzeit)
        setJobEndzeit(index.Endzeit)
        setJobPausenzeit(index.Pausenzeit)
        setJobStundenlohn(index.Stundenlohn)
        setJobZuschlag(index.Zuschlag)
        setOpenJobDialog(true)
    }

    function closeJobDialog() {
        setJobEndzeit(null)
        setJobStundenlohn(null)
        setJobBeschreibung(null)
        setJobPausenzeit(null)
        setJobStartzeit(null)
        setJobZuschlag(null)
        setOpenJobDialog(false)

    }


    function openVeranstaltungsDialog(index) {
        for (let i = 0; i < veranstaltungen.length; i++) {

            if (veranstaltungen[i].idVeranstaltungen === index) {
                setveranstaltungsTitel(veranstaltungen[i].Titel)
                setVEranstaltungsBeschreibung(veranstaltungen[i].Beschreibung)
                setVEranstaltungsDatum(veranstaltungen[i].Datum)
                setVEranstaltungsStart(veranstaltungen[i].Startzeit)
                setVEranstaltungsEnde(veranstaltungen[i].Endzeit)
                break
            }

        }

        setOpenVeranstaltungsDialog(true)
    }

    function closeVeranstaltungsDialog() {
        setveranstaltungsTitel(null)
        setVEranstaltungsBeschreibung(null)
        setVEranstaltungsDatum(null)
        setVEranstaltungsStart(null)
        setVEranstaltungsEnde(null)
        setOpenVeranstaltungsDialog(false)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    return (
        <Grid container direction="column" justify="center" alignItems="center">
            <Paper className={classes.paper}>
                <EnhancedTableToolbar/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={myJobs.length}
                        />
                        <TableBody>
                            {stableSort(myJobs, getComparator(order, orderBy))
                                .map((row) => {
                                    return (
                                        <TableRow key={row.id_JobsEmployees} hover
                                        >
                                            <TableCell align="left">{row.Job.Beschreibung}</TableCell>

                                            <TableCell
                                                align="right">{format(new Date(row.Job.Startzeit), "dd.MM.yyyy")}</TableCell>
                                            <Hidden xsDown>
                                                <TableCell align="right">{format(new Date(row.Job.Startzeit), "HH:mm")}
                                                </TableCell>
                                                <TableCell
                                                    align={"right"}>{format(new Date(row.Job.Endzeit), "HH:mm")}</TableCell>
                                            </Hidden>
                                            <TableCell align={"right"}>

                                                <Tooltip title={"Job infos"}>
                                                    <IconButton onClick={() => openJobDialog(row.Job)}>
                                                        <WorkIcon/>
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title={"Veranstaltungs infos"}>
                                                    <IconButton
                                                        onClick={() => openVeranstaltungsDialog(row.Job.VeranstaltungsID)}><EventIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <Dialog open={veranstaltungsDialog} fullWidth={true}>
                        <div className={classes.DialogTitlehintergrunde}>
                            <DialogTitle className={classes.DialogTitlefarbe}>Infos zur Veranstaltung</DialogTitle>
                        </div>
                        <DialogContent>

                            <TextField label={"Titel"} disabled={true} fullWidth={true} variant={"outlined"}
                                       value={veranstaltungsTitel}/>
                            <p/>
                            <TextField label={"Beschreibung"} disabled={true} fullWidth={true} variant={"outlined"}
                                       value={veranstaltunsBeschreibung}/>
                            <p/>
                            <KeyboardDatePicker
                                disableToolbar
                                disabled={true}
                                variant="inline"
                                format="dd.MM.yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Datum"
                                value={veranstaltunsDatum}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                fullWidth
                            />
                            <p/>
                            <KeyboardDatePicker
                                disableToolbar
                                disabled={true}
                                variant="inline"
                                format="dd.MM.yyyy HH:mm"
                                margin="normal"
                                id="date-picker-inline"
                                label="Startzeit"
                                value={veranstaltunsStart}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                fullWidth
                            />
                            <p/>
                            <KeyboardDatePicker
                                disableToolbar
                                disabled={true}
                                variant="inline"
                                format="dd.MM.yyyy HH:mm"
                                margin="normal"
                                id="date-picker-inline"
                                label="Endzeit"
                                value={veranstaltunsEnde}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <NKrotButton onClick={closeVeranstaltungsDialog}>Schließen</NKrotButton>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={jobDialog} fullWidth={true}>
                        <div className={classes.DialogTitlehintergrunde}>
                            <DialogTitle className={classes.DialogTitlefarbe}>Infos zum Job</DialogTitle>
                        </div>
                        <DialogContent>
                            <TextField label={"Titel"} disabled={true} fullWidth={true} variant={"outlined"}
                                       value={jobBeschreibung}/>
                            <p/>
                            <KeyboardDatePicker
                                disableToolbar
                                disabled={true}
                                variant="inline"
                                format="dd.MM.yyyy HH:mm"
                                margin="normal"
                                id="date-picker-inline"
                                label="Startzeit"
                                value={jobStartzeit}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                fullWidth
                            />
                            <p/>
                            <KeyboardDatePicker
                                disableToolbar
                                disabled={true}
                                variant="inline"
                                format="dd.MM.yyyy HH:mm"
                                margin="normal"
                                id="date-picker-inline"
                                label="Endzeit"
                                value={jobEndzeit}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                fullWidth
                            />
                            <p/>
                            <TextField label={"Stundenlohn"} fullWidth={true} value={jobStundenlohn} disabled={true}
                                       variant={"outlined"}/>
                            <p/>
                            <TextField label={"Zuschlag"} fullWidth={true} value={jobZuschlag} disabled={true}
                                       variant={"outlined"}/>
                            <p/>
                            <TextField label={"Pausenzeit"} fullWidth={true} value={jobPausenzeit} disabled={true}
                                       variant={"outlined"}/>
                        </DialogContent>
                        <DialogActions>
                            <NKrotButton onClick={closeJobDialog}>Schließen</NKrotButton>
                        </DialogActions>
                    </Dialog>
                </TableContainer>
            </Paper>
        </Grid>
    );
}

export default VergangendeJobs;