import Axios from "axios";


const jobQualifikationen = () => {


    const getJobQualification = async (jobsID) => {
        const response = await Axios.get(`/api/JobsQualification/${jobsID}`
        )
        return response.data

    }

    const createJobQualification = async (jobsID, qualifikationen) => {
        const response = await Axios.post('/api/JobsQualification/add', {
            jobsID,
            qualifikationen
        })
        return response
    }
    const deleteJObQualifications = async (jobsID) => {
        await Axios.delete(`/api/JobsQualification/${jobsID}`)
    }

    //TODO UPDATE UND DELETE MUSS NOCH GESCHRIEBEN WERDEN

    return {getJobQualification, createJobQualification,deleteJObQualifications}

}
export default jobQualifikationen