import Axios from "axios";


const veranstaltung = () => {


    const getVeranstaltung = async () => {
        const response = await Axios.get('/api/Contract')
        return response.data

    }


    const suchenVeranstaltung = async (Titel) => {
        const response = await Axios.get('/api/Contract/search', {
            params: {
             Titel:Titel
            }
        })
        return response.data
    }


    const getVeranstaltungforEvents = async () => {
        const response = await Axios.get('api/Contract')
        return response.data.map(veranstalltung => ({
                idVeranstaltungen: veranstalltung.idVeranstaltungen,
                title: veranstalltung.Titel,
                Beschreibung: veranstalltung.Beschreibung,
                Datum: new Date(veranstalltung.Datum),
                start: new Date(veranstalltung.Startzeit),
                end: new Date(veranstalltung.Endzeit),
                allDay: false
            })
        )
    }

    const createVeranstaltung = async (Titel, Beschreibung, Datum, Startzeit, Endzeit) => {
        const response = await Axios.post('/api/Contract/add', {
            Titel,
            Beschreibung,
            Datum,
            Startzeit,
            Endzeit,


        })
        return response.data

    }

    const postVeranstaltungofEmployee = async (idVeranstaltungen) => {
        const response = await Axios.post('api/Contract/MyJobs', {
            idVeranstaltungen
        })
        return response.data
    }


    const updateVeranstaltung = async (idVeranstaltungen, Titel, Beschreibung, Datum, Startzeit, Endzeit) => {
        await Axios.put(`/api/Contract/${idVeranstaltungen}`, {
            Titel,
            Beschreibung,
            Datum,
            Startzeit,
            Endzeit,

        })

    }
    const deleteVeranstaltung = async (idVeranstlatungen) => {
        await Axios.delete(`/api/Contract/${idVeranstlatungen}`)

    }
    return {
        getVeranstaltung,
        suchenVeranstaltung,
        getVeranstaltungforEvents,
        postVeranstaltungofEmployee,
        createVeranstaltung,
        updateVeranstaltung,
        deleteVeranstaltung
    }


}
export default veranstaltung