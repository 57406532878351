import React, {useState} from "react";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Typography from '@material-ui/core/Typography'
import {
    Grid,
    Button,
    TextField,
    Dialog,
    DialogContent,
    List,
    DialogActions,
    DialogTitle,
    DialogContentText
} from '@material-ui/core';
import useSession from "../ContextProvider/Session";
import jobMitarbeiter from "../Models/jobMitarbeiter";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles, withStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    DialogTitlehintergrunde: {
        backgroundColor: '#0e4194'
    },
    DialogTitlefarbe: {
        color: '#FFFFFF'
    }

}));

const BootstrapButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#0e4194',
        borderColor: '#0e4194',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);
const NKrotButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#a80b2a',
        borderColor: '#a80b2a',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

function MeineBuchaltung() {
    const {user} = useSession()
    const classes = useStyles();
    const [startZeitpunkt, setStartZeitpunkt] = useState(null)
    const [endZeitpunkt, setEndzeitpunkt] = useState(null)
    const [mitarbeiterjobliste, setMitarbeiterJobliste] = useState([])
    const [dialog, setopenDialog] = useState(false)
    const [verdienst, setVerdienst] = useState("")
    const [arbeitszeit, setArbeitszeit] = useState("")
    const {getJobperMonth} = jobMitarbeiter()

    function Gesamtlohnberechnung(liste) {

        let verdienst = 0
        for (let jobs of liste) {

            let jobverdienst = 0
            jobverdienst = ((((new Date(jobs.Job.Endzeit) - new Date(jobs.Job.Startzeit)) / 1000 / 60) - jobs.Job.Pausenzeit) * (jobs.Job.Stundenlohn / 60)) + jobs.Job.Zuschlag
            verdienst = verdienst + jobverdienst
        }
        verdienst = Math.round(verdienst * 100) / 100 + " €"
        setVerdienst(verdienst)
    }

    function GesammtStundenzeitrechnung(liste) {
        let gesamtzeit = 0

        for (let jobs of liste) {
            gesamtzeit = gesamtzeit + ((((new Date(jobs.Job.Endzeit) - new Date(jobs.Job.Startzeit)) / 1000) / 60) - jobs.Job.Pausenzeit)
        }
        let hours = Math.floor(gesamtzeit / 60)
        let min = Math.floor(gesamtzeit % 60)
        setArbeitszeit(hours + "h " + min + " m")

    }

    const onChooseMonth = async () => {
        let response = await getJobperMonth(user.idMitarbeiter, startZeitpunkt, endZeitpunkt)
        setMitarbeiterJobliste(response)
        Gesamtlohnberechnung(response)
        GesammtStundenzeitrechnung(response)
    }


    function openDialog() {

        onChooseMonth()
        setopenDialog(true)

    }

    function closeDialog() {

        setopenDialog(false)
    }

    const jobListe = []
    for (let mit of mitarbeiterjobliste) {
        jobListe.push(
            <ListItem key={mit.Jobs_ID} button>
                <ListItemText>{mit.Job.Beschreibung}</ListItemText>
            </ListItem>
        )
    }
    return (
        <div className={classes.root}>
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item xs={12}>
                    <p/>
                    <Typography align={'center'} variant="h4">
                        Meine Buchhaltung
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Startzeitpunkt"
                        value={startZeitpunkt}
                        onChange={setStartZeitpunkt}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <p/>
                </Grid>
                <Grid item xs={12}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Endzeitpunkt"
                        value={endZeitpunkt}
                        onChange={setEndzeitpunkt}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <p/>
                </Grid>
                <Grid item xs={12}>
                    <BootstrapButton onClick={openDialog} variant={"outlined"}> Buchhaltung öffnen</BootstrapButton>
                    <p/>
                </Grid>
                <Dialog open={dialog}>
                    <div className={classes.DialogTitlehintergrunde}>
                        <DialogTitle className={classes.DialogTitlefarbe}>Meine Buchaltung</DialogTitle>
                    </div>
                    <DialogContentText align={"center"}>Hier werden die Jobs, der Verdienst und die Arbeitszeit im
                        gewählten Zeitraum
                        angezeigt</DialogContentText>
                    <DialogContent>
                        <TextField label={"Gesamt Jobs"} disabled={true} value={mitarbeiterjobliste.length}
                                   variant={"outlined"}/>
                        <p/>
                        <List>{jobListe}</List>
                        <p/>
                        <div>
                            <TextField label={"Verdienst im Zeitraum"} disabled={true} value={verdienst}
                                       variant={"outlined"}/>
                            <TextField label={"Arbeitszeit im Zeitraum"} disabled={true}
                                       value={arbeitszeit}
                                       variant={"outlined"}/>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <NKrotButton onClick={closeDialog}>Schließen</NKrotButton>

                    </DialogActions>


                </Dialog>


            </Grid>
        </div>
    );
}

export default MeineBuchaltung;