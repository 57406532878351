import axios from "axios";

const request = () => {


    const getRequest = async () => {
        const response = await axios.get('/api/Requests')
        return response.data
    }

    const getRequestMitarbeiter = async(Mitarbeiter__ID) =>{
        const response = await axios.get(`/api/Requests/${Mitarbeiter__ID}`)
        return response.data

    }



    const createRequest = async (Jobs__ID, Mitarbeiter__ID,Veranstaltungen__ID, Datum,Status__ID) => {
        const response = await axios.post('/api/Requests/add', {
            Jobs__ID,
            Mitarbeiter__ID,
            Veranstaltungen__ID,
            Datum,
            Status__ID
        })
        return response
    }
    const updateRequest = async (idAnfragen, Status__ID) => {
        await axios.put(`/api/Requests/${idAnfragen}`, {
            Status__ID
        })

    }
    const deleteRequest = async (idAnfragen) => {
        await axios.delete(`/api/Requests/${idAnfragen}`)

    }



    return {getRequest,getRequestMitarbeiter, createRequest, updateRequest, deleteRequest}


}
export default request