import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {lighten, makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import job from "../Models/job";
import {format} from "date-fns"
import Session from "../ContextProvider/Session";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, TextField} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import {KeyboardDatePicker} from "@material-ui/pickers";
import request from "../Models/request";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import IconButton from "@material-ui/core/IconButton";


const NKrotButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#a80b2a',
        borderColor: '#a80b2a',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

const NKgrauButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#888888',
        borderColor: '#888888',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#888888',
            borderColor: '#888888',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#888888',
            borderColor: '#888888',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

const NKblauButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#0e4194',
        borderColor: '#0e4194',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Veranstaltung',
        numeric: false,
        disablePadding: true,
        label: 'Veranstaltung',
    },
    {
        id: 'Datum',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Datum',
    },
    {
        id: 'Beschreibung',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Job',
    },
    {
        id: 'Startzeit',
        numeric: true,
        label: 'Beginn',
    },
    {
        id: 'Endzeit',
        numeric: true,
        label: 'Ende'
    },
    {
        id: "Arbeitsstunden",
        numeric: true,
        label: "Arbeitszeit"
    },
    {
        id: "Status",
        numeric: true,
        label: "Status"
    }

];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        width: '100%',
    },
    pass: {
        color: '#2DA80B'
    },
    nopass: {
        color: '#f8040b'
    },
    hatbewerbungen: {
        color: '#F5DD0A'
    },
    DialogTitlehintergrunde: {
        backgroundColor: '#0e4194'
    },
    DialogTitlefarbe: {
        color: '#FFFFFF'
    }
}));

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow style={{backgroundColor: '#0e4194'}}>
                <TableCell align={headCells[0].numeric ? 'right' : 'left'}
                           padding={headCells[0].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[0].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[0].id}
                        direction={orderBy === headCells[0].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[0].id)}
                    >
                        <Typography variant={'h6'}>{headCells[0].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <TableCell align={headCells[1].numeric ? 'right' : 'left'}
                           padding={headCells[1].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[1].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[1].id}
                        direction={orderBy === headCells[1].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[1].id)}
                    >
                        <Typography variant={'h6'}>{headCells[1].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <Hidden xsDown>
                    <TableCell align={headCells[2].numeric ? 'right' : 'left'}
                               padding={headCells[2].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[2].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[2].id}
                            direction={orderBy === headCells[2].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[2].id)}
                        >
                            <Typography variant={'h6'}>{headCells[2].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align={headCells[3].numeric ? 'right' : 'left'}
                               padding={headCells[3].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[3].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[3].id}
                            direction={orderBy === headCells[3].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[3].id)}
                        >
                            <Typography variant={'h6'}>{headCells[3].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align={headCells[4].numeric ? 'right' : 'left'}
                               padding={headCells[4].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[4].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[4].id}
                            direction={orderBy === headCells[4].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[4].id)}
                        >
                            <Typography variant={'h6'}>{headCells[4].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align={headCells[5].numeric ? 'right' : 'left'}
                               padding={headCells[5].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[5].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[5].id}
                            direction={orderBy === headCells[5].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[5].id)}
                        >
                            <Typography variant={'h6'}>{headCells[5].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                </Hidden>
                <TableCell align={headCells[6].numeric ? 'right' : 'left'}
                           padding={headCells[6].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[6].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[6].id}
                        direction={orderBy === headCells[6].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[6].id)}
                    >
                        <Typography variant={'h6'}>{headCells[6].label}</Typography>
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {

    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {numSelected} = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    align={"center"}
                >
                    Stellenangebote
                </Typography>
            )}

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


function Stellenangebote() {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Veranstaltung');
    //Session Variable
    const {user} = Session()

    //Anfrage Variablen
    const [titel, setTitel] = useState("")
    const [stelle, setStelle] = useState("")
    const [datum, setDatum] = useState(new Date())
    const [startZeit, setStartzeit] = useState(new Date())
    const [Endzeit, setEndzeit] = useState(new Date())
    const [stundenlohn, setStundenlohn] = useState(0)
    const [zuschlag, setZuschlag] = useState(0)
    const [pausenzeit, setPausenzeit] = useState(0)


    //Variablen für Date aus dem Backend
    const {getAllopenJobs} = job()
    const {createRequest} = request()
    const [jobs, setJobs] = useState([])
    const [currentJob, setCurrentJob] = useState(null)


    //Dialog Variablen
    const [openAnfrage, setOpenAnfrage] = useState(false)


    const addRequest = async () => {

        await createRequest(currentJob.idJobs, user.idMitarbeiter, currentJob.VeranstaltungsID, new Date(), 1)
        closeAnfrageDialig()
    }


    function berechnearbeitszeit(num) {

        num = num / 1000 / 60
        let hour = Math.floor(num / 60)
        let min = Math.round(num % 60)

        return hour + "h " + min + "m"
    }


    function checkIfQualifite(mit, job) {

        if (job != null) {
            let jobquali = []
            let mitQuali = []

            for (let x = 0; x < job.jobsID.length; x++) {
                jobquali.push(job.jobsID[x].qualifikationen_ID)
            }

            for (let y = 0; y < mit.MitarbeiterID.length; y++) {

                mitQuali.push(mit.MitarbeiterID[y].QualifikationenID)
            }
            if (jobquali.every(function (val) {
                return mitQuali.indexOf(val) >= 0
            })) {
                return false
            } else {
                return true
            }
        }


    }

    function checkStatus(job) {


        let bewerbungen = 0
        let jobquali = []
        let mitQuali = []

        for (let x = 0; x < job.jobsID.length; x++) {
            jobquali.push(job.jobsID[x].qualifikationen_ID)
        }

        for (let y = 0; y < user.MitarbeiterID.length; y++) {
            mitQuali.push(user.MitarbeiterID[y].QualifikationenID)
        }

        for (let z = 0; z < job.Jobs__ID.length; z++) {
            if (job.Jobs__ID[z].Status__ID === 1) {
                bewerbungen = bewerbungen + 1
            }
        }

        if (jobquali.every(function (val) {
            return mitQuali.indexOf(val) >= 0
        }) && bewerbungen > 0) {
            return classes.hatbewerbungen
        }
        if (jobquali.every(function (val) {
            return mitQuali.indexOf(val) >= 0
        })) {
            return classes.pass
        } else {
            return classes.nopass
        }


    }


    function openAnfrageDialog(index) {

        setCurrentJob(index)
        setTitel(index.Contract.Titel)
        setDatum(index.Contract.Datum)
        setStartzeit(index.Startzeit)
        setEndzeit(index.Endzeit)
        setStelle(index.Beschreibung)
        setStundenlohn(index.Stundenlohn)
        setPausenzeit(index.Pausenzeit)
        setZuschlag(index.Zuschlag)
        setOpenAnfrage(true)

    }

    function closeAnfrageDialig() {
        setOpenAnfrage(false)
        setCurrentJob(null)
    }


    useEffect(() => {
        const fetchdata = async () => {
            getAllopenJobs().then(job => {
                setJobs(job)
            })
        }
        fetchdata()
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (
        <div className={classes.root}>
            <Paper spacing={3} className={classes.paper}>
                <EnhancedTableToolbar/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={jobs.length}
                        />
                        <TableBody>
                            {stableSort(jobs, getComparator(order, orderBy))
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => {
                                                openAnfrageDialog(row)
                                            }}
                                            key={row.idJobs}

                                        >
                                            <TableCell
                                                style={{padding: 20}}
                                                component="th"
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.Contract.Titel}
                                            </TableCell>
                                            <TableCell
                                                align="right">{format(new Date(row.Contract.Datum), "dd.MM.yyyy")}</TableCell>
                                            <Hidden xsDown>
                                                <TableCell align="right">{row.Beschreibung}</TableCell>

                                                <TableCell
                                                    align="right">{format(new Date(row.Startzeit), " HH:mm")}</TableCell>
                                                <TableCell
                                                    align={"right"}>{format(new Date(row.Endzeit), " HH:mm")}</TableCell>
                                                <TableCell
                                                    align={"right"}>{berechnearbeitszeit(new Date(row.Endzeit) - new Date(row.Startzeit))}</TableCell>
                                            </Hidden>
                                            <TableCell align={"right"}>
                                                <IconButton disabled={true}> <Brightness1Icon
                                                    className={checkStatus(row)} fontSize={"small"}/></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                        ;
                                })}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>


            <Dialog open={openAnfrage} fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Stelle bewerben</DialogTitle>
                </div>
                <DialogContentText align={'center'}>
                    Wollen Sie sich für die folgende Stelle bewerben ?
                </DialogContentText>
                <DialogContent>
                    <TextField disabled={true}
                               label={"Veranstaltungs Titel"}
                               value={titel}
                               variant={'outlined'}
                               fullWidth={true}/>
                    <p/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Datum"
                        value={datum}
                        disabled={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <p/>
                    <TextField title={"Stelle"}
                               variant={"outlined"}
                               disabled={true}
                               label={"Job Bezeichnung"}
                               value={stelle}
                               fullWidth={true}/>


                    <p/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Startzeit"
                        value={startZeit}
                        disabled={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />

                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Endzeit"
                        value={Endzeit}
                        disabled={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <p/>

                    <TextField disabled={true}
                               label={"Stundenlohn"}
                               value={stundenlohn}
                               variant={'outlined'}
                               fullWidth={true}/>
                    <p/>
                    <TextField disabled={true}
                               label={"Zuschlag"}
                               value={zuschlag}
                               variant={'outlined'}
                               fullWidth={true}/>
                    <p/>
                    <TextField disabled={true}
                               label={"Pausenzeit"}
                               value={pausenzeit}
                               variant={'outlined'}
                               fullWidth={true}/>

                </DialogContent>
                <DialogActions>
                    <NKblauButton onClick={addRequest}
                                  disabled={checkIfQualifite(user, currentJob)}>Bewerben</NKblauButton>
                    <NKrotButton onClick={closeAnfrageDialig}>Abbrechen</NKrotButton>
                </DialogActions>
            </Dialog>
        </div>


    );
}

export default Stellenangebote;