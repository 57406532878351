import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {lighten, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {format} from "date-fns"
import request from "../Models/request";
import useSession from "../ContextProvider/Session";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import {Hidden} from "@material-ui/core";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Titel',
        numeric: false,
        disablePadding: true,
        label: 'Veranstaltung',
    },
    {
        id: 'Datum',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Datum',
    },
    {
        id: 'Startzeit',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Beginn',
    },
    {
        id: 'Endzeit',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Ende',
    },
    {
        id: 'Beschreibung',
        numeric: true,
        label: 'Job',
    },
    {
        id: 'Status',
        numeric: true,
        label: 'Status',
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        width: '100%',
    },
    angenommen: {
        color: '#2DA80B'
    },
    unbearbeitet: {
        color: '#F5DD0A'
    },
    abgelehnt: {
        color: '#FF0000'
    }
}));

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow style={{backgroundColor: '#0e4194'}}>
                <TableCell align={headCells[0].numeric ? 'right' : 'left'}
                           padding={headCells[0].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[0].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[0].id}
                        direction={orderBy === headCells[0].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[0].id)}
                    >
                        <Typography variant={'h6'}>{headCells[0].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <TableCell align={headCells[1].numeric ? 'right' : 'left'}
                           padding={headCells[1].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[1].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[1].id}
                        direction={orderBy === headCells[1].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[1].id)}
                    >
                        <Typography variant={'h6'}>{headCells[1].label}</Typography>
                    </TableSortLabel>
                </TableCell>
                <Hidden xsDown>
                    <TableCell align={headCells[2].numeric ? 'right' : 'left'}
                               padding={headCells[2].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[2].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[2].id}
                            direction={orderBy === headCells[2].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[2].id)}
                        >
                            <Typography variant={'h6'}>{headCells[2].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align={headCells[3].numeric ? 'right' : 'left'}
                               padding={headCells[3].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[3].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[3].id}
                            direction={orderBy === headCells[3].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[3].id)}
                        >
                            <Typography variant={'h6'}>{headCells[3].label}</Typography>
                        </TableSortLabel>
                    </TableCell>

                    <TableCell align={headCells[4].numeric ? 'right' : 'left'}
                               padding={headCells[4].disablePadding ? 'none' : 'default'}
                               sortDirection={orderBy === headCells[4].id ? order : false}
                               style={{color: '#FFFFFF', padding: 20}}>
                        <TableSortLabel
                            active={orderBy === headCells[4].id}
                            direction={orderBy === headCells[4].id ? order : 'asc'}
                            onClick={createSortHandler(headCells[4].id)}
                        >
                            <Typography variant={'h6'}>{headCells[4].label}</Typography>
                        </TableSortLabel>
                    </TableCell>
                </Hidden>
                <TableCell align={headCells[5].numeric ? 'right' : 'left'}
                           padding={headCells[5].disablePadding ? 'none' : 'default'}
                           sortDirection={orderBy === headCells[5].id ? order : false}
                           style={{color: '#FFFFFF', padding: 20}}>
                    <TableSortLabel
                        active={orderBy === headCells[5].id}
                        direction={orderBy === headCells[5].id ? order : 'asc'}
                        onClick={createSortHandler(headCells[5].id)}
                    >
                        <Typography variant={'h6'}>{headCells[5].label}</Typography>
                    </TableSortLabel>
                </TableCell>

            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {

    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {numSelected} = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    align={"center"}
                >
                    Meine gestellten Anfragen
                </Typography>
            )}

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


function MeineAnfragen() {
    const {user} = useSession()
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const {getRequestMitarbeiter} = request()
    const [meineAnfragen, setMeineAnfragen] = useState([])

    useEffect(() => {
        const fetchdata = async () => {
            getRequestMitarbeiter(user.idMitarbeiter).then(anfrage => {

                setMeineAnfragen(anfrage)
                console.log(anfrage)
            })
        }
        fetchdata()
    }, [])

    function testStatus(row) {
        if (row.Status__ID === 1) {
            return classes.unbearbeitet
        }
        if (row.Status__ID === 2) {
            return classes.abgelehnt
        }
        if (row.Status__ID === 3) {
            return classes.angenommen
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"

                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={meineAnfragen.length}
                        />
                        <TableBody>
                            {stableSort(meineAnfragen, getComparator(order, orderBy))
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={row.idAnfragen}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                padding="none"
                                                style={{padding: 20}}
                                            >
                                                {row.Contract.Titel}

                                            </TableCell>
                                            <TableCell
                                                align="right">{format(new Date(row.Contract.Datum), "dd.MM.yyyy")}</TableCell>
                                            <Hidden xsDown>
                                                <TableCell
                                                    align="right">{format(new Date(row.Job.Startzeit), "HH:mm")}</TableCell>
                                                <TableCell
                                                    align="right">{format(new Date(row.Job.Endzeit), "HH:mm")}</TableCell>
                                                <TableCell align="right"> {row.Job.Beschreibung}</TableCell>
                                            </Hidden>
                                            <TableCell align="right"><Brightness1Icon
                                                className={testStatus(row)}/></TableCell>
                                        </TableRow>
                                    );
                                })}

                        </TableBody>


                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default MeineAnfragen;