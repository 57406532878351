import React, {useState, useEffect} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {lighten, makeStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {FormControl, FormControlLabel, FormLabel, InputBase, Radio, RadioGroup, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import veranstaltung from "../Models/veranstaltung";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {format} from "date-fns"
import {withStyles} from '@material-ui/core/styles';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import List from "@material-ui/core/List";
import jobQualifikationen from "../Models/jobQualifikationen";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import job from "../Models/job";
import Axios from "axios";
import WorkIcon from '@material-ui/icons/Work';
import jobMitarbeiter from "../Models/jobMitarbeiter";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        width: '100%',
    },
    row: {
        backgroundColor: '#0e4194'
    },
    nichtQulifiziert: {
        color: '#FF0000'

    },
    qulifiziert: {
        color: '#2DA80B'
    },

    fertig: {
        color: '#2DA80B'
    },
    jobsFehlen: {
        color: '#FF0000'
    },
    Jobsoffen: {
        color: '#F5DD0A'
    },
    Toolbarroot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    divider: {
        width: "15%",
    },
    linkerButton: {
        width: "40%"
    },
    mitButton: {
        width: "40%"
    },
    DialogTitlehintergrunde: {
        backgroundColor: '#0e4194'
    },
    DialogTitlefarbe: {
        color: '#FFFFFF'
    }


}))
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    divider: {
        width: "15%",
    },
    linkerButton: {
        width: "40%"
    },
    mitButton: {
        width: "40%"
    }


}));
const BootstrapButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#0e4194',
        borderColor: '#0e4194',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0e4194',
            borderColor: '#0e4194',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);
const NKrotButton = withStyles({
    root: {
        color: '#FFFFFF',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#a80b2a',
        borderColor: '#a80b2a',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#a80b2a',
            borderColor: '#a80b2a',
        },
        //  '&:focus': {
        //      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'Veranstaltung',
        numeric: false,
        disablePadding: true,
        label: 'Veranstaltung',
    },
    {
        id: 'Datum',
        numeric: false, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Datum',
    },
    {
        id: 'Aktionen',
        numeric: true, //hier Muss datum in String geändert werden ! Stringvergleich von Name übernehmen
        label: 'Aktionen',
    },
    {
        id: 'Status',
        numeric: true,
        label: 'Status',
    }
];

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow style={{backgroundColor: '#0e4194'}}>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{color: '#FFFFFF', padding: 20}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Typography variant={'h6'}>{headCell.label}</Typography>


                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


function Veranstalltungbearbeiten() {
    const classes = useStyle();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Veranstaltung');
    //Varaibalen für die Veranstaltungen
    const [Datum, setDatum] = useState(new Date())
    const [Titel, setTitel] = useState(null)
    const [Beschreibung, setBeschreibung] = useState(null)
    const [Endzeit, setEndzeit] = useState(new Date())
    const [Startzeit, setStartZeit] = useState(new Date())
    const [suche, setSuche] = useState(null)

    //Variablen für den Job
    const [jobStartzeit, setJobStartZeit] = useState(null)
    const [jobBeschreibung, setJobBeschreibung] = useState(null)
    const [jobEndzeit, setJobEndzeit] = useState(null)
    const [anzahl, setAnzahl] = useState(1)
    const [stundenlohn, setStundenlohn] = useState(0)
    const [zuschlag, setZuschlag] = useState(0)
    const [pausenZeit, setPausenzeit] = useState(0)
    const [sendMail, setSendmail] = useState(false)


    //importierte Funktionen
    const {
        getVeranstaltung,
        suchenVeranstaltung,
        updateVeranstaltung,
        deleteVeranstaltung,
        createVeranstaltung
    } = veranstaltung()
    const {getJob, createJob, updateJob, deleteJob} = job()
    const {getJobQualification, createJobQualification, deleteJObQualifications} = jobQualifikationen()
    const {getJobEmployees, createJobEmployee, updateJobEmployee, deleteJObEmployee} = jobMitarbeiter()

    //List Variablen
    const [Veranstaltungen, setVeranstalungen] = useState([])
    const [checkedQualifikation, setCheckedQualifikation] = useState([])
    const [choosenEmployee, setChoosenEmployee] = useState(null)
    const [qualifications, setQualifications] = useState([])
    const [jobs, setJobs] = useState([])
    const [mitarbeiter, setMitarbeiter] = useState([])

    //CurrentVariablen
    const [currentVeranstaltung, setCurrentVeranstaltung] = useState(null)
    const [currentJob, setCurrentJob] = useState(null)

    //DialogVariablen
    const [openCreate, setOpenCreate] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [openAddJob, setOpenAddJob] = useState(false)
    const [openJobAuswahl, setOpenJobAuswahl] = useState(false)
    const [openJob, setOpenJob] = useState(false)
    const [allgemeineMail, setAllgemeineMail] = useState(false)
    const [veranstalltungMail, setVeranstalltungMail] = useState(false)

    useEffect(() => {
        const fetchdata = async () => {
            getVeranstaltung().then(ver => {

                setVeranstalungen(ver)
            })
        }
        if (suche == null || typeof suche == "undefined" || suche.length === 0)
            fetchdata()
    }, [Veranstaltungen])

    useEffect(() => {
        if (suche != null && typeof suche != "undefined") {
            suchenVeranstaltung(suche).then(response => {
                setVeranstalungen(response)
            })
        }

    }, [suche])

    const fetchQualis = async () => {
        const response = await Axios.get('/api/Qualificationen')
        setQualifications(response.data)
    }
    const fetchMitarbeiter = async () => {
        const response = await Axios.get("/api/Employees")
        setMitarbeiter(response.data)
    }

    useEffect(() => {
        if (currentVeranstaltung != null) {
            getJob(currentVeranstaltung.idVeranstaltungen).then(li => {
                setJobs(li)
            })
        }

    }, [currentVeranstaltung])

    useEffect(() => {
        if (currentJob != null) {
            getJobQualification(currentJob.idJobs).then(li => {
                setCheckedQualifikation(li.reduce((acc, cur) => [...acc, cur.qualifikationen_ID], []))

            })
            getJobEmployees(currentJob.idJobs).then(liste => {
                if (liste != null) {
                    setChoosenEmployee(liste.Employee)
                }

            })
        }

    }, [currentJob])

    const onUpdateVeranstaltung = async () => {

        if (Titel == null || Titel === "" || Titel.startsWith(" ") || Beschreibung == null || Beschreibung === "" || Beschreibung.startsWith(" ") ||
            Datum == null || Startzeit == null || Endzeit == null) {

            alert("Ein Pflichtfeld ist nicht ausgefüllt oder beginnt mit einer Leertaste")
        } else {
            Startzeit.setFullYear(Datum.getFullYear(), Datum.getMonth(), Datum.getDate())
            Endzeit.setFullYear(Datum.getFullYear(), Datum.getMonth(), Datum.getDate())
            if (Endzeit <= Startzeit) {
                Endzeit.setFullYear(Startzeit.getFullYear(), Startzeit.getMonth(), Startzeit.getDate() + 1)
            }
            await updateVeranstaltung(currentVeranstaltung.idVeranstaltungen, Titel, Beschreibung, Datum, Startzeit, Endzeit)
            setVeranstalungen(alteVeranstaltung => alteVeranstaltung.map(ver => {
                if (ver.idVeranstaltungen === currentVeranstaltung.idVeranstaltungen) {
                    return {
                        idVeranstaltungen: currentVeranstaltung.idVeranstaltungen,
                        Titel: Titel,
                        Beschreibung: Beschreibung,
                        Datum: format(new Date(Datum), "yyyy-MM-dd"),
                        Startzeit: Startzeit,
                        Endzeit: Endzeit,
                    }
                } else {
                    return ver
                }
            }))
            closeEditDialog()
        }
    }

    const onCreateVeranstaltung = async () => {

        if (Titel == null || Titel === "" || Titel.startsWith(" ") || Beschreibung == null || Beschreibung === "" || Beschreibung.startsWith(" ") ||
            Datum == null || Startzeit == null || Endzeit == null) {
            alert("Ein Pflichtfeld ist nicht ausgefüllt oder beginnt mit einer Leertaste")
        } else {
            Startzeit.setFullYear(Datum.getFullYear(), Datum.getMonth(), Datum.getDate())
            Endzeit.setFullYear(Datum.getFullYear(), Datum.getMonth(), Datum.getDate())
            if (Endzeit <= Startzeit) {
                setEndzeit(Endzeit.setFullYear(Startzeit.getFullYear(), Startzeit.getMonth(), Startzeit.getDate() + 1))
            }
            const neueVeranstaltung = await createVeranstaltung(Titel, Beschreibung, Datum, Startzeit, Endzeit)
            setVeranstalungen([...Veranstaltungen, neueVeranstaltung])
            closeCreateDialog()
        }
    }

    const onDeleteVeranstaltung = async () => {
        await deleteVeranstaltung(currentVeranstaltung.idVeranstaltungen)
        setVeranstalungen(oldVeranstaltung => oldVeranstaltung.filter(veranstaltung =>
            currentVeranstaltung.idVeranstaltungen !== veranstaltung._id))
        setOpenDelete(false)
    }

    function openCreateDialog() {
        //Function to create
        setOpenCreate(true)

    }

    function openEditDialog(s) {
        //Function to open
        setCurrentVeranstaltung(s)
        setOpenEdit(true)
        setTitel(s.Titel)
        setBeschreibung(s.Beschreibung)
        setDatum(new Date(s.Datum))
        setStartZeit(new Date(s.Startzeit))
        setEndzeit(new Date(s.Endzeit))

    }

    function closeEditDialog() {

        setOpenEdit(false)
        setTitel(null)
        setBeschreibung(null)
        setDatum(new Date())
        setStartZeit(new Date())
        setEndzeit(new Date())

    }

    function closeCreateDialog() {

        setOpenCreate(false)
        setTitel(null)
        setBeschreibung(null)
        setDatum(new Date())
        setStartZeit(new Date())
        setEndzeit(new Date())

    }

    function openDeleteDialog(s) {
        //Function to open EditDialog
        setCurrentVeranstaltung(s)
        setOpenDelete(true)
    }

    function closeDeleteDialog() {
        //Function to close EditDialog
        setOpenDelete(false)
        setTitel("")
        setBeschreibung("")
        setDatum(new Date())
        setStartZeit(new Date())
        setEndzeit(new Date())
    }

    function openAddJobDialog(index) {
        setCurrentVeranstaltung(index)
        setJobStartZeit(new Date(index.Startzeit))
        setJobEndzeit(new Date(index.Startzeit))

        setOpenAddJob(true)

    }

    function closeAddJobDialog() {
        setChoosenEmployee(null)
        setCheckedQualifikation([])
        setJobStartZeit(null)
        setJobEndzeit(null)
        setJobBeschreibung(null)
        setStundenlohn(0)
        setPausenzeit(0)
        setZuschlag(0)
        setAnzahl(1)
        setSendmail(false)
        setOpenAddJob(false)
        setCurrentVeranstaltung(null)
    }

    function openJobAuswahlDialog(index) {
        setCurrentVeranstaltung(index)
        setOpenJobAuswahl(true)
    }

    function closeJobAuswalDialog() {
        setOpenJobAuswahl(false)
        setCurrentVeranstaltung(null)
        setJobs([])
    }

    function openJobDialog(index) {
        setCurrentJob(index)
        setJobBeschreibung(index.Beschreibung)
        setJobStartZeit(new Date(index.Startzeit))
        setJobEndzeit(new Date(index.Endzeit))
        setPausenzeit(index.Pausenzeit)
        setStundenlohn(index.Stundenlohn)
        setZuschlag(index.Zuschlag)
        setOpenJob(true)
    }

    function closeJobDialog() {
        setChoosenEmployee(null)
        setCheckedQualifikation([])
        setCurrentJob(null)
        setOpenJob(false)
    }

    function openAllgeminDialog() {
        setAllgemeineMail(true)
    }

    function closeAllgemeinDialog() {
        setAllgemeineMail(false)
    }

    function openVeranstaltungsMailDialog(row) {
        setCurrentVeranstaltung(row)
        setVeranstalltungMail(true)
    }

    function closeVeranstaltungsMailDialog() {
        setCurrentVeranstaltung(null)
        setVeranstalltungMail(false)
    }

    const onCreateJobQualification = async (index) => {
        await createJobQualification(index, checkedQualifikation)
    }

    const onCreateJob = async () => {
        let neuerJob
        if (isNaN(stundenlohn) || typeof stundenlohn != "number" || stundenlohn < 0 || isNaN(pausenZeit) ||
            typeof pausenZeit != "number" || pausenZeit < 0 || isNaN(zuschlag) || typeof zuschlag != "number"
            || zuschlag < 0 || jobBeschreibung == null || jobBeschreibung === "" || jobBeschreibung.startsWith(" ")
            || jobStartzeit == null || Datum == null || jobEndzeit == null) {
            alert("Ein Eingabefehler ist aufgetreten. Überprüfen Sie ihre Eingaben.")
        } else {
            if (jobEndzeit <= jobStartzeit) {
                setJobEndzeit(jobEndzeit.setFullYear(jobStartzeit.getFullYear(), jobStartzeit.getMonth(),
                    jobStartzeit.getDate() + 1))
            }
            for (let i = 0; i < anzahl; i++) {

                if (choosenEmployee != null && new Date(jobStartzeit) < new Date()) {
                    //Mitarbeiter vergangenheit
                    neuerJob = await createJob(jobStartzeit, currentVeranstaltung.idVeranstaltungen,
                        jobBeschreibung, jobEndzeit, stundenlohn, zuschlag, pausenZeit, 5)
                } else {
                    if (new Date(jobStartzeit) < new Date() && choosenEmployee == null) {
                        //kein mitarbeiter vergangenheit
                        neuerJob = await createJob(jobStartzeit, currentVeranstaltung.idVeranstaltungen,
                            jobBeschreibung, jobEndzeit, stundenlohn, zuschlag, pausenZeit, 4)
                    } else {
                        //kein mitarbeiter zukunft
                        neuerJob = await createJob(jobStartzeit, currentVeranstaltung.idVeranstaltungen,
                            jobBeschreibung, jobEndzeit, stundenlohn, zuschlag, pausenZeit, 7)
                    }
                    if (choosenEmployee != null && new Date(jobStartzeit) >= new Date()) {
                        //mitarbeiter zukunft
                        neuerJob = await createJob(jobStartzeit, currentVeranstaltung.idVeranstaltungen,
                            jobBeschreibung, jobEndzeit, stundenlohn, zuschlag, pausenZeit, 6)
                    }
                }
                if (checkedQualifikation.length > 0) {
                    await onCreateJobQualification(neuerJob.data.idJobs)
                }
                if (choosenEmployee != null && anzahl === 1) {
                    await onAddJobEmploy(neuerJob.data.idJobs)
                }
                setJobs([...jobs, neuerJob])
            }
            if (sendMail == true) {
                SendingJobMails()
            }
            closeAddJobDialog()
        }
    }
    const onAddJobEmploy = async (index) => {
        await createJobEmployee(index, choosenEmployee.idMitarbeiter)


    }

    const onUpdateJobEmployee = async (index) => {
        await updateJobEmployee(index, choosenEmployee.idMitarbeiter)
    }
    const onDeleteJob = async () => {
        await deleteJob(currentJob.idJobs)
        setJobs(oldJobs => oldJobs.filter(job =>
            currentVeranstaltung.idJobs !== job._id))
        closeJobDialog()
        closeJobAuswalDialog()
    }

    const onDeleteJObEmployee = async () => {

        await deleteJObEmployee(currentJob.idJobs)
        closeJobDialog()
        closeJobAuswalDialog()
    }

    const OnupdateJob = async () => {

        if (isNaN(stundenlohn) || typeof stundenlohn != "number" || stundenlohn < 0 || isNaN(pausenZeit) ||
            typeof pausenZeit != "number" || pausenZeit < 0 || isNaN(zuschlag) || typeof zuschlag != "number"
            || zuschlag < 0 || jobBeschreibung == null || jobBeschreibung === "" || jobBeschreibung.startsWith(" ")
            || jobStartzeit == null || Datum == null || jobEndzeit == null) {
            alert("Ein Eingabefehler ist aufgetreten. Überprüfen Sie ihre Eingaben.")
        } else {

            if (jobEndzeit <= jobStartzeit) {
                setJobEndzeit(jobEndzeit.setFullYear(jobStartzeit.getFullYear(), jobStartzeit.getMonth(),
                    jobStartzeit.getDate() + 1))
            }

            if (choosenEmployee != null && new Date(jobStartzeit) < new Date()) {
                //Mitarbeiter vergangenheit
                await updateJob(currentJob.idJobs, jobStartzeit, jobBeschreibung, jobEndzeit, stundenlohn, zuschlag,
                    pausenZeit, 5)
            } else {
                if (new Date(jobStartzeit) < new Date() && choosenEmployee == null) {
                    //kein mitarbeiter vergangenheit
                    await updateJob(currentJob.idJobs, jobStartzeit, jobBeschreibung, jobEndzeit, stundenlohn,
                        zuschlag, pausenZeit, 4)
                } else {
                    //kein mitarbeiter zukunft
                    await updateJob(currentJob.idJobs, jobStartzeit, jobBeschreibung, jobEndzeit, stundenlohn,
                        zuschlag, pausenZeit, 7)
                }
                if (choosenEmployee != null && new Date(jobStartzeit) >= new Date()) {
                    //mitarbeiter zukunft
                    await updateJob(currentJob.idJobs, jobStartzeit, jobBeschreibung, jobEndzeit, stundenlohn,
                        zuschlag, pausenZeit, 6)
                }
            }
            setJobs(alteJobs => alteJobs.map(job => {
                if (job.idJobs === currentJob.idJobs) {
                    return {
                        jobStartzeit: new Date(jobStartzeit),
                        jobBeschreibung: jobBeschreibung,
                        jobEndzeit: jobEndzeit,
                        stundenlohn: stundenlohn,
                        zuschlag: zuschlag,
                        pausenZeit: pausenZeit,
                    }
                } else {
                    return job
                }
            }))

            if (choosenEmployee != null) {
                await onUpdateJobEmployee(currentJob.idJobs)
            }
            await deleteJObQualifications(currentJob.idJobs)
            if (checkedQualifikation.length > 0) {

                await onCreateJobQualification(currentJob.idJobs)
            }
            closeJobDialog()
            closeJobAuswalDialog()
        }
    }

    let JobListe = []
    for (let Job of jobs) {
        JobListe.push(
            <ListItem key={Job.idJobs} onClick={() => {

                openJobDialog(Job)

            }} button>
                <ListItemText>{Job.Beschreibung}</ListItemText>

            </ListItem>
        )
    }
    const handleCheckboxToggleQulifikationen = (value) => () => {
        const currentIndex = checkedQualifikation.indexOf(value)
        const newChecked = [...checkedQualifikation]

        if (currentIndex === -1) {
            newChecked.push(value)

        } else {
            newChecked.splice(currentIndex, 1)

        }

        setCheckedQualifikation(newChecked)

    }

    const handleChange = (event) => {
        setChoosenEmployee(event);
    };

    function checkeIfQulifiate(mit, job) {

        if (job != null) {
            let mitQuali = []

            for (let y = 0; y < mit.MitarbeiterID.length; y++) {

                mitQuali.push(mit.MitarbeiterID[y].QualifikationenID)
            }

            if (checkedQualifikation.length === 0) {
                return classes.qulifiziert
            }
            if (checkedQualifikation.every(function (val) {
                return mitQuali.indexOf(val) >= 0
            })) {
                return classes.qulifiziert
            } else {
                return classes.nichtQulifiziert
            }
        }
    }

    function checkEventStatus(ver) {

        if (typeof ver.VeranstaltungsID == 'undefined' || ver.VeranstaltungsID.length === 0) {
            return classes.jobsFehlen
        }

        let jobStatus = []

        for (let y = 0; y < ver.VeranstaltungsID.length; y++) {

            jobStatus.push(ver.VeranstaltungsID[y].StatusID)
        }

        if (jobStatus.includes(7)) {
            return classes.Jobsoffen
        }
        return classes.fertig
    }

    function checkAnzahl() {
        if (anzahl > 1) {
            return true
        } else {
            return false
        }
    }

    function SendingJobMails(mit) {

        let mitQuali = []
        let empfaenger = []
        for (let mit of mitarbeiter) {
            if (mit.idMitarbeiter === 54 || mit.idMitarbeiter === 61) {
                continue
            }
            for (let y = 0; y < mit.MitarbeiterID.length; y++) {
                mitQuali.push(mit.MitarbeiterID[y].QualifikationenID)
            }
            if (checkedQualifikation.length === 0 || checkedQualifikation.every(function (val) {
                return mitQuali.indexOf(val) >= 0
            })) {
                empfaenger.push(mit)
            }
        }
        Axios.post('/api/Jobs/sendJobMails', {
            Titel: currentVeranstaltung.Titel,
            jobTitel: jobBeschreibung,
            empfaenger: empfaenger,
            betreff: currentVeranstaltung.Titel + ": Offene Schicht " + jobBeschreibung
        }).then(r => {
            console.log(r)
        })

    }

    function sendEventMails(chase) {
        let empfaenger = []
        if (chase === 1) {
            for (let mit of mitarbeiter) {
                if (mit.idMitarbeiter === 54 || mit.idMitarbeiter === 61) {
                    continue
                }
                empfaenger.push(mit)
            }
            Axios.post('/api/Jobs/sendEventMails', {
                empfaenger: empfaenger,
                betreff: "Offene Schichten",
                typ: chase
            }).then(r => {
                console.log(r)
            })

        } else {

            for (let mit of mitarbeiter) {
                if (mit.idMitarbeiter === 54 || mit.idMitarbeiter === 61) {
                    continue
                }
                empfaenger.push(mit)
            }

            Axios.post('/api/Jobs/sendEventMails', {
                empfaenger: empfaenger,
                Titel: currentVeranstaltung.Titel,
                betreff: currentVeranstaltung.Titel + ": Offene Schichten",
                typ: chase
            }).then(r => {
                console.log(r)
            })
        }
    }

    const EnhancedTableToolbar = (props) => {
        const classes = useToolbarStyles();
        const {numSelected} = props;

        return (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                <NKrotButton className={classes.linkerButton} onClick={openCreateDialog} style={{color: '#FFFFFF'}}
                             variant={'outlined'}> Veranstaltung Erstellen</NKrotButton>
                <div className={classes.divider}/>
                <BootstrapButton className={classes.mitButton} variant={"outlined"} onClick={() => {
                    fetchMitarbeiter()
                    openAllgeminDialog()
                }}>Allgemeine E-Mail versenden </BootstrapButton>
                <div className={classes.divider}/>
                <TextField label={"Nachname"} type={"search"} value={suche} onChange={e => {
                    setSuche(e.target.value)

                }}/>

            </Toolbar>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <div className={classes.root}>
            <Toolbar className={classes.Toolbarroot}>
                <NKrotButton className={classes.linkerButton} onClick={openCreateDialog} style={{color: '#FFFFFF'}}
                             variant={'outlined'}> Veranstaltung Erstellen</NKrotButton>
                <div className={classes.divider}/>
                <BootstrapButton className={classes.mitButton} variant={"outlined"} onClick={() => {
                    fetchMitarbeiter()
                    openAllgeminDialog()
                }}>Allgemeine E-Mail versenden </BootstrapButton>
                <div className={classes.divider}/>
                <InputBase
                    placeholder="Suchen(Name)"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    value={suche}
                    onChange={e => setSuche(e.target.value)}
                    inputProps={{'aria-label': 'search'}}
                />
            </Toolbar>
            <Paper className={classes.paper}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={Veranstaltungen.length}
                        />

                        <TableBody>
                            {stableSort(Veranstaltungen, getComparator(order, orderBy))
                                .map((row) => {
                                    return (
                                        <TableRow key={row.idVeranstaltungen} hover>
                                            <TableCell align="left">{row.Titel}</TableCell>
                                            <TableCell
                                                align="left">{format(new Date(row.Datum), "dd.MM.yyyy")}</TableCell>
                                            <TableCell align="right">

                                                <Tooltip title={"Job Erstellen"}>
                                                    <IconButton onClick={() => {
                                                        fetchQualis()
                                                        fetchMitarbeiter()
                                                        openAddJobDialog(row)
                                                    }}>
                                                        <AddCircleOutlineIcon/>
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title={"Jobliste anzeigen"}>
                                                    <IconButton onClick={() => {
                                                        fetchQualis()
                                                        fetchMitarbeiter()
                                                        openJobAuswahlDialog(row)
                                                    }}>
                                                        <WorkIcon/>
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title={"Email für die Veranstaltung verschicken"}>
                                                    <IconButton onClick={() => {
                                                        fetchMitarbeiter()
                                                        openVeranstaltungsMailDialog(row)
                                                    }}>
                                                        <MailOutlineIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Veranstaltung bearbeiten"}>
                                                    <IconButton onClick={() => {
                                                        openEditDialog(row);
                                                    }}>
                                                        <EditIcon style={{color: '#0e4194'}}/>
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title={"Veranstaltung löschen"}>
                                                    <IconButton onClick={() => {
                                                        openDeleteDialog(row);
                                                    }}>
                                                        <DeleteIcon style={{color: '#a80b2a'}}/>
                                                    </IconButton>
                                                </Tooltip>


                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    <Brightness1Icon className={checkEventStatus(row)}/>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Dialog open={openEdit} aria-labelledby="form-dialog-title" fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Veranstaltung bearbeiten</DialogTitle>
                </div>
                <DialogContentText align={'center'}>
                    Hier können Sie die ausgewählte Veranstaltung bearbeiten.
                </DialogContentText>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Titel"
                        label="Titel*"
                        value={Titel}
                        onChange={event => setTitel(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Beschreibung"
                        label="Beschreibung*"
                        value={Beschreibung}
                        onChange={event => setBeschreibung(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Datum"
                        value={Datum}
                        onChange={setDatum}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <p/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Startzeit"
                        value={Startzeit}
                        onChange={setStartZeit}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth

                    />
                    <p/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Endzeit"
                        value={Endzeit}
                        onChange={setEndzeit}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <p/>
                </DialogContent>
                <DialogActions>
                    <BootstrapButton onClick={onUpdateVeranstaltung} variant={"outlined"}>Speichern</BootstrapButton>
                    <NKrotButton onClick={closeEditDialog} variant={"outlined"}>Abbrechen</NKrotButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} aria-labelledby="form-dialog-title" fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Veranstaltung löschen</DialogTitle>
                </div>
                <DialogContentText align={'center'}>
                    Wollen Sie die Veranstaltung wirklich löschen ?
                </DialogContentText>
                <DialogActions>
                    <BootstrapButton onClick={onDeleteVeranstaltung} color='#FFFFFF'>Ja</BootstrapButton>
                    <NKrotButton onClick={closeDeleteDialog} color='#FFFFFF'>Nein</NKrotButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openCreate} aria-labelledby="form-dialog-title" fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Veranstaltung erstellen</DialogTitle>
                </div>
                <DialogContentText align={'center'}>
                    Hier können Sie eine Veranstaltung erstellen.
                </DialogContentText>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Titel"
                        label="Titel*"
                        value={Titel}
                        onChange={event => setTitel(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Beschreibung"
                        label="Beschreibung*"
                        value={Beschreibung}
                        onChange={event => setBeschreibung(event.target.value)}
                        fullWidth
                    />
                    <p/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Datum"
                        value={Datum}
                        onChange={setDatum}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <p/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Startzeit"
                        value={Startzeit}
                        onChange={setStartZeit}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <p/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Endzeit"
                        value={Endzeit}
                        onChange={setEndzeit}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <p/>
                </DialogContent>
                <DialogActions>
                    <BootstrapButton onClick={onCreateVeranstaltung} variant={"outlined"}>Veranstaltung
                        erstellen</BootstrapButton>
                    <NKrotButton onClick={closeCreateDialog} variant={"outlined"}>Abbrechen</NKrotButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openAddJob} fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Job Erstellen</DialogTitle>
                </div>
                <DialogContentText align={"center"}>Hier Können sie Jobs für die ausgewählte Veranstaltung
                    erstellen</DialogContentText>
                <DialogContent>

                    <p/>
                    <FormControl>
                        <InputLabel id="demo-simple-select-outlined-label">Anzahl</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={event => setAnzahl(event.target.value)
                            }
                            label="Anzahl"
                            value={anzahl}>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                        </Select>
                    </FormControl>
                    <p/>
                    <TextField label={"Job Beschreibung*"} value={jobBeschreibung}
                               onChange={e => setJobBeschreibung(e.target.value)}
                               fullWidth={true} variant={"outlined"}/>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Startzeit"
                        value={jobStartzeit}
                        onChange={setJobStartZeit}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth/>

                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Endzeit"
                        value={jobEndzeit}
                        onChange={setJobEndzeit}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth/>
                    <p/>
                    <TextField fullWidth={true}
                               label={"Stundenlohn*"}
                               variant={"outlined"}
                               type="number"
                               InputProps={{inputProps: {min: 0}}}
                               error={stundenlohn < 0 || typeof stundenlohn != "number" || isNaN(stundenlohn)}
                               helperText={stundenlohn < 0 || isNaN(stundenlohn) ? 'Nur Postive Zahlen sind möglich' : ' '}
                               value={stundenlohn}
                               onChange={e => {
                                   setStundenlohn(parseFloat(e.target.value.replace(',', '.').replace(' ', '')))
                               }

                               }/>

                    <p/>

                    <TextField fullWidth={true}
                               label={"Zuschlag*"}
                               variant={"outlined"}
                               value={zuschlag}
                               type="number"
                               InputProps={{inputProps: {min: 0}}}
                               error={zuschlag < 0 || typeof zuschlag != "number" || isNaN(zuschlag)}
                               helperText={zuschlag < 0 || isNaN(zuschlag) ? 'Nur Postive Zahlen sind möglich' : ' '}
                               onChange={e =>
                                   setZuschlag(parseFloat(e.target.value.replace(',', '.').replace(' ', '')))
                               }/>

                    <p/>
                    <TextField fullWidth={true}
                               label={"Pausen Zeit in min*"}
                               variant={"outlined"}
                               type="number"
                               value={pausenZeit}
                               InputProps={{inputProps: {min: 0}}}
                               error={pausenZeit < 0 || typeof pausenZeit != "number" || isNaN(pausenZeit)}
                               helperText={pausenZeit < 0 || isNaN(pausenZeit) ? 'Nur Postive Zahlen sind möglich' : ' '}
                               onChange={e => setPausenzeit(parseFloat(e.target.value.replace(',', '.').replace(' ', '')))
                               }/>

                    <p/>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={sendMail}
                                onChange={e => setSendmail(e.target.checked)}
                                name="SendMail"
                                color="primary"
                            />
                        }
                        label="Benachrichtungen verschicken"
                    />
                    <FormGroup>
                        Ausgewählte Qualifikationen
                        <List dense className={classes.userList}>
                            {qualifications?.filter(Quali => checkedQualifikation.indexOf(Quali.idQualifikationen) >= 0).map((quali, index) => (
                                    <ListItem key={index} button>
                                        <ListItemText id={index} primary={quali.Typ}/>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={handleCheckboxToggleQulifikationen(quali.idQualifikationen)}
                                                checked={checkedQualifikation.indexOf(quali.idQualifikationen) !== -1}
                                                inputProps={{'aria-labelledby': index}}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ) || <Button/>
                            )}
                        </List>
                        <p/>
                        Restliche Qualifikatioen
                        <List dense className={classes.userList}>

                            {qualifications?.filter(Quali => checkedQualifikation.indexOf(Quali.idQualifikationen) === -1).map((quali, index) => (
                                    <ListItem key={index} button>

                                        <ListItemText id={index} primary={quali.Typ}/>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={handleCheckboxToggleQulifikationen(quali.idQualifikationen)}
                                                checked={checkedQualifikation.indexOf(quali.idQualifikationen) !== -1}
                                                inputProps={{'aria-labelledby': index}}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ) || <Button/>
                            )}
                        </List>
                    </FormGroup>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Mitarbeiter</FormLabel>
                        <RadioGroup aria-label="Mitarbeiter" name="Mitarbeiter" value={choosenEmployee}>
                            {mitarbeiter.map((mit) => (

                                <FormControlLabel value={mit} control={<Radio/>}
                                                  label={mit.Vorname + " " + mit.Nachname}
                                                  onClick={() => handleChange(mit)}
                                                  disabled={checkAnzahl()}/>
                            ))}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <BootstrapButton onClick={onCreateJob}>Speichern</BootstrapButton>
                    <NKrotButton onClick={closeAddJobDialog}>Schließen</NKrotButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openJobAuswahl} fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Übersicht aller zu Veranstaltung gehörigen
                        Jobs </DialogTitle>
                </div>
                <DialogContent>
                    <List>{JobListe}</List>
                </DialogContent>
                <DialogActions>
                    <NKrotButton onClick={closeJobAuswalDialog}>Schließen</NKrotButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openJob} fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}> Hier können sie informationen zum Job
                        einsehen</DialogTitle>
                </div>
                <DialogContent>
                    <TextField
                        fullWidth={true}
                        variant={'outlined'}
                        label={"Stelle"}
                        value={jobBeschreibung}
                        onChange={event => setJobBeschreibung(event.target.value)}/>
                    <p/>

                    <KeyboardDatePicker
                        fullWidth={true}
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Startzeit"
                        value={jobStartzeit}
                        onChange={setJobStartZeit}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                    <p/>
                    <KeyboardDatePicker
                        fullWidth={true}
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        label="Endzeit"
                        value={jobEndzeit}
                        onChange={setJobEndzeit}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />

                    <p/>
                    <TextField
                        fullWidth={true}
                        label={"Stundenlohn*"}
                        variant={"outlined"}
                        type="number"
                        value={stundenlohn}
                        InputProps={{inputProps: {min: 0}}}
                        error={stundenlohn < 0 || typeof stundenlohn != "number" || isNaN(stundenlohn)}
                        helperText={stundenlohn < 0 || isNaN(stundenlohn) ? 'Nur Postive Zahlen sind möglich' : ' '}
                        onChange={e => {
                            setStundenlohn(parseFloat(e.target.value.replace(',', '.').replace(' ', '')))
                        }

                        }/>
                    <p/>
                    <TextField
                        fullWidth={true}
                        label={"Zuschlag*"}
                        variant={"outlined"}
                        value={zuschlag}
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        error={zuschlag < 0 || typeof zuschlag != "number" || isNaN(zuschlag)}
                        helperText={zuschlag < 0 || isNaN(zuschlag) ? 'Nur Postive Zahlen sind möglich' : ' '}
                        onChange={e => {
                            setZuschlag(parseFloat(e.target.value.replace(',', '.').replace(' ', '')))
                        }
                        }/>
                    <p/>
                    <TextField
                        fullWidth={true}
                        label={"Pausenzeit*"}
                        variant={"outlined"}
                        value={pausenZeit}
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        error={pausenZeit < 0 || typeof pausenZeit != "number" || isNaN(pausenZeit)}
                        helperText={pausenZeit < 0 || isNaN(pausenZeit) ? 'Nur Postive Zahlen sind möglich' : ' '}
                        onChange={e => {
                            setPausenzeit(parseFloat(e.target.value.replace(',', '.').replace(' ', '')))
                        }
                        }/>
                    <p/>

                    <FormGroup>
                        Ausgewählte Qualifikationen
                        <List dense className={classes.userList}>
                            {qualifications?.filter(Quali => checkedQualifikation.indexOf(Quali.idQualifikationen) >= 0).map((quali, index) => (
                                    <ListItem key={index} button>
                                        <ListItemText id={index} primary={quali.Typ}/>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={handleCheckboxToggleQulifikationen(quali.idQualifikationen)}
                                                checked={checkedQualifikation.indexOf(quali.idQualifikationen) !== -1}
                                                inputProps={{'aria-labelledby': index}}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ) || <Button/>
                            )}
                        </List>
                        <p/>
                        Restliche Qualifikatioen
                        <List dense className={classes.userList}>

                            {qualifications?.filter(Quali => checkedQualifikation.indexOf(Quali.idQualifikationen) === -1).map((quali, index) => (
                                    <ListItem key={index} button>

                                        <ListItemText id={index} primary={quali.Typ}/>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={handleCheckboxToggleQulifikationen(quali.idQualifikationen)}
                                                checked={checkedQualifikation.indexOf(quali.idQualifikationen) !== -1}
                                                inputProps={{'aria-labelledby': index}}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ) || <Button/>
                            )}
                        </List>
                    </FormGroup>

                    <FormControl component="fieldset">
                        <FormLabel component="legend">Mitarbeiter</FormLabel>
                        <RadioGroup aria-label="Mitarbeiter" name="Mitarbeiter" value={choosenEmployee}>
                            {mitarbeiter.map((mit) => (
                                <FormControlLabel className={checkeIfQulifiate(mit, currentJob)} value={mit}
                                                  control={<Radio/>}
                                                  label={mit.Vorname + " " + mit.Nachname}
                                                  onClick={() => {
                                                      handleChange(mit)
                                                  }} checked={mit.idMitarbeiter === choosenEmployee?.idMitarbeiter}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <BootstrapButton onClick={OnupdateJob}>Speichern</BootstrapButton>
                    <BootstrapButton onClick={onDeleteJObEmployee}>Mitarbeiter aus Job entfernen</BootstrapButton>
                    <BootstrapButton onClick={onDeleteJob}>Löschen</BootstrapButton>
                    <NKrotButton onClick={closeJobDialog}>Schließen</NKrotButton>
                </DialogActions>
            </Dialog>
            <Dialog open={allgemeineMail} fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Eine Allgemeine Mail versenden ?</DialogTitle>
                </div>
                <DialogContentText align={"center"}>Wollen Sie eine Allgemeine Mail an alle Mitarbeiter versenden
                    ?</DialogContentText>
                <DialogActions>
                    <BootstrapButton variant={"outlined"} onClick={() => {
                        sendEventMails(1)
                        closeAllgemeinDialog()
                    }}> Mails Versenden</BootstrapButton>
                    <NKrotButton variant={"outlined"} onClick={closeAllgemeinDialog}>Nein</NKrotButton>
                </DialogActions>

            </Dialog>
            <Dialog open={veranstalltungMail} fullWidth={true}>
                <div className={classes.DialogTitlehintergrunde}>
                    <DialogTitle className={classes.DialogTitlefarbe}>Mail versenden ?</DialogTitle>
                </div>
                <DialogContentText align={"center"}>Wollen sie eine eine Mail für diese Veranstaltung versenden
                    ?</DialogContentText>
                <DialogActions>
                    <BootstrapButton variant={"outlined"} onClick={() => {
                        sendEventMails(2)
                        closeVeranstaltungsMailDialog()
                    }}> Mails Versenden</BootstrapButton>
                    <NKrotButton variant={"outlined"} onClick={closeVeranstaltungsMailDialog}>Nein</NKrotButton>
                </DialogActions>

            </Dialog>
        </div>
    );
}

export default Veranstalltungbearbeiten;