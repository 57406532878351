import React from "react";
import './App.css';
import  {SessionProvider} from "./ContextProvider/Session";
import Root from "./Root";
function App() {

    return (
        <SessionProvider>
            <Root/>
        </SessionProvider>
    );
}

export default App;
