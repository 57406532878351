
import Axios from "axios";

const mitarbeiterQualifikationen = () => {


    const getMitarbeiterQualification = async (MitarbeiterID) => {
        const response = await Axios.get(`/api/EmployeeQualification/${MitarbeiterID}`
        )
        return response.data

    }

    const createMitarbeiterQualification = async (MitarbeiterID, qualifikationen) => {
        const response = await Axios.post('/api/EmployeeQualification/add', {
            MitarbeiterID,
            qualifikationen
        })
        return response
    }


   const deleteMitarbeiterQualification= async (MitarbeiterID) =>{
        await Axios.delete(`/api/EmployeeQualification/${MitarbeiterID}`)
   }

    return {getMitarbeiterQualification, createMitarbeiterQualification,deleteMitarbeiterQualification}

}
export default mitarbeiterQualifikationen