import React, {useEffect} from "react";
import Calender from './Components/Calender'
import Arbeitsanfragen from './Components/Arbeitsanfragen'
import Buchhaltung from './Components/Buchhaltung'
import Mitarbeitergruppen from './Components/Mitarbeitergrupppen'
import Veranstaltungenbearbeiten from './Components/Veranstaltungbearbeiten'
import Stellenangebote from "./Components/Stellenangebote";
import Meinprofil from "./Components/Meinprofil";
import MeineAnfragen from "./Components/MeineAnfragen";
import Home from './Components/Home'
import Nav from './Components/Nav'
import QualifikationenErstellen from "./Components/QualifikationenErstellen";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import './App.css';
import useSession from "./ContextProvider/Session";
import PrivateRoute from "./PrivateRoute";
import VergangendeJobs from "./Components/VergangendeJobs";
import MeineJobs from "./Components/MeineJobs";
import MeineBuchaltung from "./Components/MeineBuchaltung";

function Root() {
    const {getUser} = useSession()
    useEffect(() => {
        getUser()

    }, [])

    return (
        <div style={{height: '100vh'}}>
            <Router>
                <Nav>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Switch>
                            <Route exact path="/" component={Home}/>
                            <PrivateRoute path="/arbeitsanfragen" component={Arbeitsanfragen} minimumRequiredRole={2}/>
                            <PrivateRoute path="/calender" component={Calender}/>
                            <PrivateRoute path="/buchhaltung" component={Buchhaltung} minimumRequiredRole={2}/>
                            <PrivateRoute path="/mitarbeitergruppen" component={Mitarbeitergruppen}
                                          minimumRequiredRole={2}/>
                            <PrivateRoute path="/QualifikationenErstellen" component={QualifikationenErstellen}
                                          minimumRequiredRole={1}/>
                            <PrivateRoute path="/veranstaltungenbearbeiten" component={Veranstaltungenbearbeiten}
                                          minimumRequiredRole={2}/>
                            <PrivateRoute path="/Stellenangebote" component={Stellenangebote}/>
                            <PrivateRoute path="/VergangendeJobs" component={VergangendeJobs}/>
                            <PrivateRoute path="/Meinprofil" component={Meinprofil}/>
                            <PrivateRoute path="/MeineAnfragen" component={MeineAnfragen}/>
                            <PrivateRoute path="/MeineJobs" component={MeineJobs}/>
                            <PrivateRoute path="/Buchhaltung" component={Buchhaltung} minimumRequiredRole={2}/>
                            <PrivateRoute path="/MeineBuchhaltung" component={MeineBuchaltung} />
                        </Switch>
                    </MuiPickersUtilsProvider>
                </Nav>
            </Router>
        </div>

    );
}

export default Root;
